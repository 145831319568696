@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Old+Mincho:wght@400;500;600;700&display=swap");
/* reset */
html {
  overflow-y: scroll;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  box-sizing: border-box;
}

dt,
th,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

section,
figure,
aside,
header,
footer,
main,
nav {
  display: block;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
}

table {
  border-collapse: collapse;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: "";
}

object,
embed {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
abbr,
acronym,
fieldset {
  border: 0;
}

li {
  list-style-type: none;
}

img {
  vertical-align: bottom;
}

hr {
  clear: both;
}

/* --------------------------------------------------------
	01share style
	-------------------------------------------------------- */
.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

.fwb {
  font-weight: bold;
}

.fwn {
  font-weight: normal;
}

/*CSS Rollover*/
a.cssRoll img {
  opacity: 1;
}

a:hover.cssRoll img {
  opacity: 0.6;
}

body {
  font-weight: normal;
  line-height: 1.7;
  color: #373737;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

figure {
  display: block;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

em {
  font-weight: bold;
}

/* ---------------------------
module
--------------------------- */
@media screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}
@media screen and (max-width: 1023px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: "Zen Old Mincho", serif;
  font-weight: 400;
  font-style: normal;
  color: #343A40;
  -webkit-text-size-adjust: 100%;
  line-height: 1.7;
  overflow: clip;
}
img {
  max-width: 100%;
  height: auto;
}

a {
  transition: 0.3s;
  color: #343A40;
}
a:hover {
  opacity: 0.7;
}

button {
  font-family: "Zen Old Mincho", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  color: #343A40;
}

a:not([href^="tel:"]) {
  transition: 0.2s;
}
a[href^="tel:"] {
  cursor: default;
}
@media screen and (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
button {
  cursor: pointer;
}

select {
  background-color: #fff;
  color: #343A40;
}

.hero {
  width: 100%;
}
.hero img {
  width: 100%;
}

/* ---------------------------
footer
--------------------------- */
.l-footer {
  position: relative;
  background-color: #EBEBEC;
  background-image: url(../images/common/img_texture_01.webp);
  background-size: contain;
  background-repeat: repeat;
}
@media screen and (min-width: 768px) {
  .l-footer {
    padding-top: 100px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__inner {
    padding: 0 45px;
  }
}
.l-footer__row {
  display: flex;
}
@media screen and (min-width: 768px) {
  .l-footer__row {
    padding-bottom: 155px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__row {
    flex-direction: column;
  }
}
.l-footer__logo {
  position: relative;
  z-index: 5;
}
@media screen and (min-width: 768px) {
  .l-footer__logo {
    width: 24.5454545455%;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__logo {
    max-width: 122px;
    margin-inline: auto;
  }
}
.l-footer__nav {
  position: relative;
  z-index: 5;
  display: grid;
}
@media screen and (min-width: 768px) {
  .l-footer__nav {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 43.1818181818%;
    margin-left: 103px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__nav {
    margin-top: 25px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.l-footer__nav__col {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-footer__nav__col {
    gap: 22px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__nav__col {
    gap: 20px;
  }
}
.l-footer__nav__item {
  display: flex;
  flex-direction: column;
}
.l-footer__nav__link {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .l-footer__nav__link {
    letter-spacing: 0.125em;
  }
}
.l-footer__nav__meganav {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-footer__nav__meganav {
    margin-top: 10px;
    gap: 3px;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__nav__meganav {
    margin-top: 5px;
    gap: 1px;
  }
}
.l-footer__nav__meganav__link {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  opacity: 0.6;
  letter-spacing: 0.07em;
}
@media screen and (min-width: 768px) {
  .l-footer__nav__meganav__link {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__nav__meganav__link {
    font-size: 0.8571428571rem;
    letter-spacing: 0.0833333333em;
  }
}
.l-footer__mark {
  position: absolute;
}
@media screen and (min-width: 768px) {
  .l-footer__mark {
    top: 85px;
    right: 55px;
    width: min(328px, 22.7777777778vw);
  }
}
@media screen and (max-width: 767px) {
  .l-footer__mark {
    width: 302px;
    bottom: 0;
    right: 18px;
  }
}
.l-footer__copyright {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  opacity: 0.6;
  letter-spacing: 0.07em;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .l-footer__copyright {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__copyright {
    font-size: 0.8571428571rem;
    letter-spacing: 0.0833333333em;
    margin-top: 45px;
  }
}

/* ---------------------------
l-header
--------------------------- */
.l-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 250;
}
@media screen and (min-width: 1024px) {
  .l-header {
    height: 105px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header {
    height: 80px;
  }
}
@media screen and (max-width: 767px) {
  .l-header {
    height: 60px;
  }
}
.l-header__inner {
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .l-header__inner {
    padding: 0 60px 0 54px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__inner {
    padding: 0 40px 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__inner {
    padding: 0 20px;
  }
}
.l-header__row {
  height: 100%;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .l-header__row__left {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .l-header__row__left {
    width: 122px;
  }
}
@media screen and (min-width: 1024px) {
  .l-header__row__right {
    width: 110px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__row__right {
    width: 70px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__row__right {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 20px;
  }
}
.l-header__logo {
  display: block;
}
@media screen and (min-width: 1024px) {
  .l-header__logo {
    width: 200px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__logo {
    width: 150px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__logo {
    width: 122px;
  }
}
.l-header__description {
  line-height: 1.8333;
}
@media screen and (min-width: 1024px) {
  .l-header__description {
    font-size: 0.75rem;
    margin-left: 40px;
  }
}
@media screen and (max-width: 1023px) {
  .l-header__description {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .l-header__nav {
    margin-left: auto;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .l-header__nav {
    display: none;
  }
}
.l-header__nav__list {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .l-header__nav__list {
    gap: 18px;
    height: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__nav__list {
    gap: 14px;
  }
}
.l-header__nav__list__item {
  position: relative;
}
@media screen and (min-width: 768px) {
  .l-header__nav__list__item {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.l-header__nav__list__item:has(.l-header__nav__megaMenu):hover .l-header__nav__megaMenu {
  pointer-events: auto;
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
.l-header__nav__list__link {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .l-header__nav__list__link {
    font-size: 0.875rem;
  }
}
.l-header__nav__list__link:hover {
  color: #C0AD49;
}
.l-header__nav__megaMenu {
  position: absolute;
  top: calc(100% - 20px);
  right: 0;
  min-width: 260px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
}
.l-header__nav__megaMenu__item a {
  display: block;
  padding: 8px 12px;
  border-bottom: 1px solid #EFEFEF;
  font-size: 0.875rem;
}
.l-header__nav__megaMenu__item a:hover {
  color: #C0AD49;
  opacity: 1;
}
.l-header__humberger {
  position: relative;
  display: block;
  border-radius: 0;
  background: none;
  border: none;
  z-index: 500;
}
@media screen and (min-width: 1024px) {
  .l-header__humberger {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-left: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__humberger {
    width: 36px;
    height: 36px;
    margin-top: 4px;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .l-header__humberger {
    width: 33px;
    height: 33px;
  }
}
.l-header__humberger .line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #343A40;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.3s;
}
@media screen and (min-width: 768px) {
  .l-header__humberger .line:first-child {
    transform: translate(-50%, calc(-50% + 4px));
  }
}
@media screen and (max-width: 767px) {
  .l-header__humberger .line:first-child {
    transform: translate(-50%, calc(-50% + 4px));
  }
}
@media screen and (min-width: 768px) {
  .l-header__humberger .line:last-child {
    transform: translate(-50%, calc(-50% - 4px));
  }
}
@media screen and (max-width: 767px) {
  .l-header__humberger .line:last-child {
    transform: translate(-50%, calc(-50% - 4px));
  }
}
@media screen and (min-width: 768px) {
  .--open .l-header__humberger .line {
    background-color: #fff;
  }
}
.--open .l-header__humberger .line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}
.--open .l-header__humberger .line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.l-header__modalNav {
  height: 100vh;
  position: fixed;
  right: 0;
  background-color: #343A40;
  z-index: 250;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav {
    top: 0;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav {
    top: 60px;
  }
}
.--open .l-header__modalNav {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav {
    width: 720px;
    padding: 170px 100px 170px 60px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav {
    width: 100vw;
    padding: 55px 45px 80px;
  }
}
.l-header__modalNav::before {
  content: "";
  display: block;
  aspect-ratio: 305/287;
  position: absolute;
  background-image: url(../images/common/icon_communication_01_primary.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav::before {
    width: 305px;
    bottom: -35px;
    right: 60px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav::before {
    max-width: 305px;
    width: calc(100% - 55px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.l-header__modalNav__nav {
  display: flex;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav {
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__nav {
    flex-direction: column;
    gap: 30px;
  }
}
.l-header__modalNav__nav__col {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav__col {
    gap: 35px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__nav__col {
    flex-direction: column;
    gap: 30px;
  }
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav__col:first-child {
    width: 32%;
  }
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav__col:last-child {
    flex: 1;
    margin-left: 30px;
  }
}
.l-header__modalNav__nav__link {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav__link {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__nav__link {
    font-size: 1.4285714286rem;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__nav__link.--toggle {
    display: flex;
    position: relative;
  }
  .l-header__modalNav__nav__link.--toggle::before, .l-header__modalNav__nav__link.--toggle::after {
    content: "";
    display: block;
    width: 18px;
    height: 2px;
    position: absolute;
    top: 14px;
    right: 0;
    background-color: #fff;
    transform-origin: center;
    transition: 0.3s;
  }
  .l-header__modalNav__nav__link.--toggle::after {
    transform: rotate(90deg);
  }
  .l-header__modalNav__nav__link.--toggle.--open::after {
    transform: rotate(0deg);
  }
}
.l-header__modalNav__nav__childNav {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav__childNav {
    gap: 5px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__nav__childNav {
    gap: 6px;
    margin-top: 6px;
  }
}
.l-header__modalNav__nav__childNav__link {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.07em;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__nav__childNav__link {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__nav__childNav__link {
    font-size: 0.9285714286rem;
  }
}
.l-header__modalNav__sns {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-header__modalNav__sns {
    max-width: 260px;
    margin-top: 62px;
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__modalNav__sns {
    gap: 10px;
    margin-top: 40px;
  }
}
.l-header__sns {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .l-header__sns {
    z-index: 50;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 180px;
    right: 0;
    width: 170px;
    gap: 33px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__sns {
    z-index: 50;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 150px;
    right: 0;
    width: 110px;
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__sns {
    display: none;
  }
}
.l-header__sns__link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .l-header__sns__link {
    gap: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .l-header__sns__link .icon {
    width: 62px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-header__sns__link .icon {
    width: 48px;
  }
}
.l-header__sns__link .label {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  writing-mode: vertical-lr;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.14em;
}
@media screen and (min-width: 768px) {
  .l-header__sns__link .label {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 768px) {
  .l-header__sns02 {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .l-header__sns02 {
    display: flex;
    gap: 12px;
    margin-left: auto;
    margin-right: 14px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__sns02__link {
    width: 27.5px;
  }
}

/* ---------------------------
page
--------------------------- */
@media screen and (min-width: 768px) {
  .l-page {
    flex-direction: row-reverse;
  }
}
.l-page__head {
  display: block;
}
/* ---------------------------
l-wrap
--------------------------- */
.l-wrap {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .l-wrap {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 40px;
  }
}
.l-wrap--900 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .l-wrap--900 {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 40px;
  }
}
.l-wrap--980 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .l-wrap--980 {
    max-width: 1060px;
    margin: 0 auto;
    padding: 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .l-wrap--sp {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* ---------------------------
button
--------------------------- */
.c-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border: 1px solid #343A40;
  line-height: 1.5;
  letter-spacing: 0.08em;
  position: relative;
  background-color: #fff;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .c-button {
    max-width: 315px;
    width: 100%;
    min-height: 54px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .c-button {
    font-size: 0.8571428571rem;
    min-height: 52px;
    width: calc(100% - 14px);
  }
}
.c-button::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .c-button::after {
    background: linear-gradient(to right, #343A40 25px, #343A40 25px, #fff 25px);
    width: 40px;
    right: -15px;
  }
}
@media screen and (max-width: 767px) {
  .c-button::after {
    background: linear-gradient(to right, #343A40 29px, #343A40 29px, #fff 29px);
    width: 43px;
    right: -14px;
  }
}
.c-button.--bg {
  background-color: #343A40;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .c-button.--bg::after {
    background: linear-gradient(to right, #fff 25px, #fff 25px, #343A40 25px);
  }
}
@media screen and (max-width: 767px) {
  .c-button.--bg::after {
    background: linear-gradient(to right, #fff 29px, #fff 29px, #343A40 29px);
  }
}
@media screen and (min-width: 768px) {
  .c-button.--small {
    max-width: 208px;
    min-height: 42px;
  }
}
@media screen and (max-width: 767px) {
  .c-button.--small {
    min-height: 45px;
  }
}
@media screen and (min-width: 768px) {
  .c-button.--medium {
    min-height: 48px;
  }
}
@media screen and (max-width: 767px) {
  .c-button.--medium {
    width: calc(100% - 7px);
    min-height: 46px;
  }
}
@media screen and (max-width: 767px) {
  .c-button.--medium::after {
    width: 24px;
    right: -7px;
    background: linear-gradient(to right, #343A40 17px, #343A40 17px, #fff 17px);
  }
}
@media screen and (max-width: 767px) {
  .c-button.--medium:hover::after {
    background: linear-gradient(to right, #fff 17px, #fff 17px, #343A40 17px);
  }
}
@media screen and (max-width: 767px) {
  .c-button.--medium.--bg::after {
    background: linear-gradient(to right, #fff 17px, #fff 17px, #343A40 17px);
  }
}
@media screen and (max-width: 767px) {
  .c-button.--medium.--bg:hover::after {
    background: linear-gradient(to right, #343A40 17px, #343A40 17px, #fff 17px);
  }
}
@media screen and (min-width: 768px) {
  .c-button.--large {
    max-width: 335px;
    min-height: 60px;
  }
}
@media screen and (max-width: 767px) {
  .c-button.--large {
    min-height: 52px;
  }
}
.c-button.--center {
  margin-inline: auto;
}
.c-button:hover {
  background-color: #C0AD49;
  border-color: #C0AD49;
  color: #fff;
}
.c-button:hover::after {
  background-color: #fff;
}
.c-button02 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border: 1px solid #343A40;
  line-height: 1.5;
  letter-spacing: 0.08em;
  position: relative;
  background-color: #fff;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .c-button02 {
    max-width: 315px;
    width: 100%;
    min-height: 54px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .c-button02 {
    font-size: 0.8571428571rem;
    min-height: 52px;
    width: calc(100% - 15px);
  }
}
.c-button02::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #343A40;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .c-button02::after {
    width: 40px;
    right: -15px;
  }
}
@media screen and (max-width: 767px) {
  .c-button02::after {
    width: 43px;
    right: -15px;
  }
}
.c-button02.--center {
  margin-inline: auto;
}

/* ---------------------------
heading
--------------------------- */
.c-heading {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  color: #C0AD49;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .c-heading {
    font-size: 3.75rem;
    letter-spacing: 0.06em;
    line-height: 1.25;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .c-heading {
    font-size: 3.25rem;
    letter-spacing: 0.06em;
    line-height: 1.25;
  }
}
@media screen and (max-width: 767px) {
  .c-heading {
    font-size: 2.5rem;
    letter-spacing: 0.0571428571em;
    line-height: 1.25;
    line-height: 1.1428571429;
  }
}
@media screen and (min-width: 1024px) {
  .c-heading.--small {
    font-size: 2.5rem;
    letter-spacing: 0.1em;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .c-heading.--small {
    font-size: 2.25rem;
  }
}
@media screen and (max-width: 767px) {
  .c-heading.--small {
    font-size: 1.7142857143rem;
    letter-spacing: 0.1em;
  }
}
.c-heading.--capitalize {
  text-transform: capitalize;
}

.c-heading02 .en {
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .c-heading02 .en {
    font-size: 6.25rem;
    letter-spacing: 0.06em;
    line-height: 1.125;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .c-heading02 .en {
    font-size: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-heading02 .en {
    font-size: 3.2857142857rem;
    letter-spacing: 0.06em;
    line-height: 1.125;
  }
}
.c-heading02 .ja {
  color: #C0AD49;
  position: relative;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .c-heading02 .ja {
    font-size: 1.5rem;
    letter-spacing: 0.04em;
    padding-left: 26px;
    margin-top: 4px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .c-heading02 .ja {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 767px) {
  .c-heading02 .ja {
    font-size: 1.1428571429rem;
    letter-spacing: 0.04em;
    padding-left: 20px;
    margin-top: 3px;
  }
}
.c-heading02 .ja::before {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .c-heading02 .ja::before {
    width: 14px;
    top: 17px;
    left: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .c-heading02 .ja::before {
    top: 14px;
  }
}
@media screen and (max-width: 767px) {
  .c-heading02 .ja::before {
    width: 12px;
    top: 14px;
    left: 3px;
  }
}

/* ---------------------------
news
--------------------------- */
.c-newsCard__head {
  position: relative;
}
.c-newsCard__thumbnail {
  overflow: hidden;
}
.c-newsCard__thumbnail img {
  width: 100%;
  transition: transform 0.3s ease-out;
}
.c-newsCard__category {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #C0AD49;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .c-newsCard__category {
    bottom: 0;
    left: 0;
    background-color: #fff;
    position: absolute;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    line-height: 1.5;
    padding: 3px 12px;
  }
}
@media screen and (max-width: 767px) {
  .c-newsCard__category {
    font-size: 0.7857142857rem;
    line-height: 1.5;
    margin-top: 6px;
  }
}
.c-newsCard__heading {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 768px) {
  .c-newsCard__heading {
    line-height: 1.7142857143;
    font-size: 0.875rem;
    margin-top: 14px;
  }
}
@media screen and (max-width: 767px) {
  .c-newsCard__heading {
    font-size: 0.8571428571rem;
    letter-spacing: 0.0714285714em;
    line-height: 1.7142857143;
    margin-top: 4px;
  }
}
.c-newsCard__meta {
  display: flex;
}
@media screen and (min-width: 768px) {
  .c-newsCard__meta {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .c-newsCard__meta {
    margin-top: 8px;
  }
}
.c-newsCard__date {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 768px) {
  .c-newsCard__date {
    font-size: 0.75rem;
    width: 80px;
  }
}
@media screen and (max-width: 767px) {
  .c-newsCard__date {
    font-size: 0.7142857143rem;
  }
}
.c-newsCard__place {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
@media screen and (min-width: 768px) {
  .c-newsCard__place {
    margin-left: 16px;
    gap: 6px;
  }
}
@media screen and (max-width: 767px) {
  .c-newsCard__place {
    gap: 6px;
    margin-left: 10px;
  }
}
.c-newsCard__place__item {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  background-color: #C0AD49;
  color: #fff;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  height: fit-content;
}
@media screen and (min-width: 768px) {
  .c-newsCard__place__item {
    font-size: 0.75rem;
    padding: 4px 10px;
  }
}
@media screen and (max-width: 767px) {
  .c-newsCard__place__item {
    font-size: 0.7142857143rem;
    padding: 4px 12px;
  }
}

/* ---------------------------
sentence
--------------------------- */
.c-sentence {
  display: flex;
  flex-direction: column;
  gap: 1.9em;
}
.c-sentence p {
  color: #002B5C;
}
@media screen and (min-width: 768px) {
  .c-sentence p {
    font-size: 1.125rem;
  }
}
/* ---------------------------
js
--------------------------- */
.js-modalOverlay:has(.js-modalList.--open) {
  position: fixed;
  inset: 0;
}

body:has(.js-modalList.--open) {
  position: fixed;
  overflow-y: scroll;
}

/* ---------------------------
access
--------------------------- */
.p-access {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-access {
    padding-top: 60px;
    padding-bottom: 115px;
  }
}
@media screen and (max-width: 767px) {
  .p-access {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}
.p-access::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .p-access::before {
    width: min(100vw - 20px, 1120px + (100vw - 1020px) / 2);
  }
}
@media screen and (max-width: 767px) {
  .p-access::before {
    width: 100%;
  }
}
.p-access__wrapper {
  position: relative;
}
.p-access__wrapper::before, .p-access__wrapper::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-access__wrapper::before, .p-access__wrapper::after {
    width: min(1192px, 100% - 40px);
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 768px) {
  .p-access__wrapper::before {
    top: 45px;
  }
}
@media screen and (min-width: 768px) {
  .p-access__wrapper::after {
    bottom: -50px;
  }
}
.p-access__inner {
  position: relative;
}
.p-access__inner::after {
  content: "";
  display: block;
  width: 1px;
  position: absolute;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-access__inner::after {
    height: calc(100% + 115px);
    bottom: -115px;
    left: -20px;
  }
}
.p-access__heading {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #C0AD49;
  line-height: 1.5;
  position: relative;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-access__heading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-access__heading {
    font-size: 1.7142857143rem;
  }
}
.p-access__body {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-access__contents {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-access__contents {
    margin-top: 18px;
  }
}
.p-access__contents dl {
  display: flex;
  justify-content: space-between;
}
.p-access__contents dl dt,
.p-access__contents dl dd {
  line-height: 0.1em;
  line-height: 1.75;
}
@media screen and (min-width: 768px) {
  .p-access__contents dl dt,
  .p-access__contents dl dd {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .p-access__contents dl dt,
  .p-access__contents dl dd {
    font-size: 1.1428571429rem;
  }
}
@media screen and (min-width: 768px) {
  .p-access__contents dl dt {
    width: 110px;
  }
}
@media screen and (max-width: 767px) {
  .p-access__contents dl dt {
    width: 75px;
  }
}
.p-access__contents dl dd {
  flex: 1;
}
@media screen and (min-width: 768px) {
  .p-access__contents dl dd {
    margin-left: 37px;
  }
}
@media screen and (max-width: 767px) {
  .p-access__contents dl dd {
    margin-left: 5px;
  }
}
.p-access__map {
  display: grid;
}
@media screen and (min-width: 768px) {
  .p-access__map {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 38px;
  }
}
@media screen and (max-width: 767px) {
  .p-access__map {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 20px;
    gap: 15px;
  }
}
.p-access__map__item img,
.p-access__map__item iframe {
  display: block;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .p-access__map__item iframe {
    aspect-ratio: 450/360;
  }
}
.p-access__button {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .p-access__button {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-access__button {
    margin-top: 24px;
  }
}

/* ---------------------------
bg
--------------------------- */
.p-bg {
  position: relative;
}
.p-bg::before {
  content: "";
  display: block;
  height: calc(100% + 600px);
  position: absolute;
  top: 0;
  left: -33.3333333333%;
  border-radius: 9999px 9999px 0 0;
  background-color: #EBEBEC;
  background-image: url(../images/common/img_texture_01.webp);
  background-size: 2967px auto;
  background-repeat: repeat;
  z-index: -5;
}
@media screen and (min-width: 768px) {
  .p-bg::before {
    width: 231.0416666667%;
  }
}
@media screen and (max-width: 767px) {
  .p-bg::before {
    width: 291.6666666667%;
  }
}

/* ---------------------------
contact
--------------------------- */
.p-contact {
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .p-contact {
    padding: 62px 150px 90px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-contact {
    padding: 62px 40px 90px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact {
    padding: 32px 20px 60px;
  }
}
.p-contact__text {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.11em;
}
@media screen and (min-width: 768px) {
  .p-contact__text {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__text {
    font-size: 1.1428571429rem;
  }
}
.p-contact__list {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-contact__list {
    margin-top: 60px;
    gap: 38px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__list {
    margin-top: 32px;
    gap: 24px;
  }
}
.p-contact__list dl {
  display: flex;
  flex-direction: column;
}
.p-contact__list dl dt {
  letter-spacing: 0.125em;
  font-weight: 600;
  position: relative;
  width: fit-content;
}
.p-contact__list dl dt.req::after {
  content: "＊";
  color: #C0AD49;
  position: absolute;
  top: 0;
  right: -1.5em;
}
@media screen and (min-width: 768px) {
  .p-contact__list dl dt.req::after {
    font-size: 0.625rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__list dl dt.req::after {
    font-size: 0.5714285714rem;
  }
}
@media screen and (min-width: 768px) {
  .p-contact__list dl dd {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__list dl dd {
    margin-top: 14px;
  }
}
.p-contact__list dl dd input,
.p-contact__list dl dd textarea {
  font-family: "Zen Old Mincho", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  width: 100%;
  letter-spacing: 0.125em;
  border: none;
  background-color: #EFEFEF;
}
@media screen and (min-width: 768px) {
  .p-contact__list dl dd input,
  .p-contact__list dl dd textarea {
    padding: 14px 16px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__list dl dd input,
  .p-contact__list dl dd textarea {
    padding: 12px 14px;
  }
}
.p-contact__list dl dd select {
  font-family: "Zen Old Mincho", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  width: 100%;
  letter-spacing: 0.125em;
  border: none;
  background-color: #EFEFEF;
}
@media screen and (min-width: 768px) {
  .p-contact__list dl dd select {
    padding: 14px 16px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__list dl dd select {
    width: 100%;
    padding: 12px 14px;
  }
}
@media screen and (min-width: 768px) {
  .p-contact__privacy {
    margin-top: 45px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy {
    margin-top: 32px;
    padding: 0 8px;
  }
}
.p-contact__privacy__heading {
  letter-spacing: 0.125em;
  position: relative;
}
.p-contact__privacy__text {
  letter-spacing: 0.1em;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-contact__privacy__text {
    font-size: 0.875rem;
    margin-top: 12px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy__text {
    font-size: 0.8571428571rem;
    margin-top: 10px;
  }
}
.p-contact__privacy__contents {
  border-top: 1px solid #343A40;
  border-bottom: 1px solid #343A40;
  overflow-y: scroll;
}
@media screen and (min-width: 768px) {
  .p-contact__privacy__contents {
    margin-top: 46px;
    max-height: 150px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy__contents {
    margin-top: 32px;
    max-height: 120px;
  }
}
.p-contact__privacy__contents__inner {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-contact__privacy__contents__inner {
    padding: 12px 20px;
    gap: 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy__contents__inner {
    padding: 12px 10px;
    gap: 8px;
  }
}
@media screen and (min-width: 768px) {
  .p-contact__privacy__contents__inner * {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy__contents__inner * {
    font-size: 0.8571428571rem;
  }
}
.p-contact__privacy__accept {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .p-contact__privacy__accept {
    margin-top: 33px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy__accept {
    margin-top: 24px;
  }
}
.p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 1px;
}
.p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label input:checked + .wpcf7-list-item-label::before {
  background-color: #343A40;
}
.p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label input:checked + .wpcf7-list-item-label::after {
  display: block;
}
.p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label .wpcf7-list-item-label {
  cursor: pointer;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label .wpcf7-list-item-label {
    padding-left: 26px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label .wpcf7-list-item-label {
    padding-left: 20px;
  }
}
.p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label .wpcf7-list-item-label::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 0;
  border: 1px solid #343A40;
  background-color: #fff;
}
.p-contact__privacy__accept .wpcf7-acceptance .wpcf7-list-item label .wpcf7-list-item-label::after {
  content: "";
  display: none;
  width: 7px;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 3px;
  border-top: 1.5px solid #fff;
  border-right: 1.5px solid #fff;
  transform: rotate(135deg);
}
.p-contact__submit {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .p-contact__submit {
    margin-top: 45px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__submit {
    margin-top: 32px;
  }
}
.p-contact__submit__button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .p-contact__submit__button {
    max-width: 315px;
    width: 100%;
    min-height: 54px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__submit__button {
    font-size: 0.8571428571rem;
    min-height: 52px;
    width: calc(100% - 14px);
  }
}
.p-contact__submit__button::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #343A40;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .p-contact__submit__button::after {
    width: 50px;
    right: -15px;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__submit__button::after {
    width: 43px;
    right: -14px;
  }
}
.p-contact__submit__button input {
  font-family: "Zen Old Mincho", serif;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #343A40;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.08em;
  transition: 0.3s;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .p-contact__submit__button input {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contact__submit__button input {
    font-size: 0.8571428571rem;
  }
}
.p-contact__submit__button input:not(:disabled):hover {
  border-color: #C0AD49;
  background: #C0AD49;
  color: #fff;
}
.p-contact__submit__button .wpcf7-spinner {
  display: none;
}

/* ---------------------------
faq
--------------------------- */
.p-faq {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-faq {
    padding: 90px 25px 100px 75px;
    gap: 75px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-faq {
    padding: 90px 40px 100px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq {
    padding: 32px 20px 60px;
    gap: 34px;
  }
}
.p-faq__heading {
  letter-spacing: 0.0833em;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-faq__heading {
    font-size: 1.5rem;
    padding-bottom: 29px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__heading {
    font-size: 1.2857142857rem;
    padding-bottom: 18px;
  }
}
.p-faq__heading::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .p-faq__heading::after {
    background: linear-gradient(to right, #C0AD49 108px, #DDDAC9 108px);
  }
}
@media screen and (max-width: 767px) {
  .p-faq__heading::after {
    background: linear-gradient(to right, #C0AD49 62px, #DDDAC9 62px);
  }
}
.p-faq__contents {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-faq__contents {
    margin-top: 37px;
    gap: 75px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents {
    margin-top: 20px;
    gap: 32px;
  }
}
.p-faq__contents details {
  display: flex;
  flex-direction: column;
  position: relative;
}
.p-faq__contents details[open] .question .label::after {
  transform: translateY(-50%) scaleY(0);
}
.p-faq__contents details .question {
  line-height: 1.7777777778;
  letter-spacing: 0.11em;
  list-style: none;
  position: relative;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .question {
    font-size: 1.125rem;
    padding-left: 104px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .question {
    font-size: 1.1428571429rem;
    line-height: 1.6;
    padding-left: 57px;
  }
}
.p-faq__contents details .question::-webkit-details-marker {
  display: none;
}
.p-faq__contents details .question::before {
  content: "Q";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #C0AD49;
  color: #fff;
  border-radius: 50%;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .question::before {
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .question::before {
    width: 42px;
    height: 42px;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    font-size: 1.4285714286rem;
  }
}
.p-faq__contents details .question .label {
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .question .label {
    padding-right: 145px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .question .label {
    padding-right: 32px;
  }
}
.p-faq__contents details .question .label::before, .p-faq__contents details .question .label::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center;
  transition: 0.3s;
  background-color: #C0AD49;
}
.p-faq__contents details .question .label::before {
  height: 2px;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .question .label::before {
    width: 24px;
    right: 65px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .question .label::before {
    width: 18px;
    right: 0;
  }
}
.p-faq__contents details .question .label::after {
  width: 2px;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .question .label::after {
    height: 24px;
    right: 76px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .question .label::after {
    height: 18px;
    right: 8px;
  }
}
.p-faq__contents details .answer {
  line-height: 1.7777777778;
  letter-spacing: 0.11em;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .answer {
    font-size: 1.125rem;
    padding: 0 145px 0 104px;
    margin-top: 26px;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .answer {
    padding: 0 32px 0 57px;
    margin-top: 16px;
  }
}
.p-faq__contents details .answer::before {
  content: "A";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #C0AD49;
  border-radius: 50%;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .p-faq__contents details .answer::before {
    width: 60px;
    height: fit-content;
    top: 3px;
    left: 16px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-faq__contents details .answer::before {
    width: 42px;
    height: 42px;
    top: 3px;
    left: 0px;
    font-size: 1.4285714286rem;
  }
}

/* ---------------------------
feature
--------------------------- */
.p-feature {
  display: grid;
}
@media screen and (min-width: 768px) {
  .p-feature {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-feature {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
  }
}
.p-feature__button {
  background: none;
  border: none;
  appearance: none;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.p-feature__link {
  display: block;
  position: relative;
}
.p-feature__thumbnail {
  position: relative;
  overflow: hidden;
}
.p-feature__thumbnail::before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(52, 58, 64, 0.8);
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .p-feature__thumbnail::before {
    width: 70px;
    height: 70px;
    right: -16px;
    bottom: -16px;
  }
}
@media screen and (max-width: 767px) {
  .p-feature__thumbnail::before {
    width: 42px;
    height: 42px;
    right: -7px;
    bottom: -7px;
  }
}
.p-feature__thumbnail::after {
  content: ">";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .p-feature__thumbnail::after {
    font-size: 1.5rem;
    width: 46px;
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-feature__thumbnail::after {
    font-size: 1.1428571429rem;
    width: 32px;
    height: 32px;
  }
}
.p-feature__button .p-feature__thumbnail::after {
  content: "＋";
}
.p-feature__thumbnail img {
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .p-feature__contents {
    margin-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .p-feature__contents {
    margin-top: 12px;
  }
}
.p-feature__heading {
  letter-spacing: 0.07em;
}
@media screen and (min-width: 768px) {
  .p-feature__heading {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .p-feature__heading {
    line-height: 1.5;
    font-size: 1.1428571429rem;
  }
}
.p-feature__text {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  line-height: 1.7142857143;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 768px) {
  .p-feature__text {
    font-size: 0.875rem;
    margin-top: 11px;
  }
}
@media screen and (max-width: 767px) {
  .p-feature__text {
    font-size: 0.7857142857rem;
    margin-top: 6px;
  }
}

.p-featureModalList__container {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100%;
  z-index: 5000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.p-featureModalList.--open .p-featureModalList__container {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.p-featureModalList__close {
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  border: none;
  z-index: 400;
}
@media screen and (min-width: 768px) {
  .p-featureModalList__close {
    width: 60px;
    height: 60px;
    top: 40px;
    right: 40px;
  }
}
@media screen and (max-width: 767px) {
  .p-featureModalList__close {
    width: 42px;
    height: 42px;
    top: 20px;
    right: 20px;
  }
}
.p-featureModalList__close .line {
  position: absolute;
  height: 2px;
  background-color: #343A40;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
@media screen and (min-width: 768px) {
  .p-featureModalList__close .line {
    width: 24px;
  }
}
@media screen and (max-width: 767px) {
  .p-featureModalList__close .line {
    width: 18px;
  }
}
.p-featureModalList__close .line:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.p-featureModal {
  position: absolute;
  inset: 0;
  overflow-y: auto;
  width: 100vw;
  height: 0;
  z-index: 80;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.p-featureModal.--open {
  position: relative;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  height: 100%;
}
.p-featureModal__images {
  position: relative;
}
.p-featureModal__image {
  width: 100%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-featureModal__image {
    height: 100vh;
  }
}
.p-featureModal__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-featureModal__thumbnails {
  position: fixed;
  display: grid;
}
@media screen and (min-width: 768px) {
  .p-featureModal__thumbnails {
    left: 20px;
    top: 50%;
    gap: 10px;
    width: 160px;
  }
}
@media screen and (max-width: 767px) {
  .p-featureModal__thumbnails {
    width: 90px;
    left: 10px;
    top: 50%;
    gap: 10px;
  }
}
.p-featureModal__thumbnail a {
  display: block;
  border: 2px solid transparent;
}
.p-featureModal__comment {
  position: fixed;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .p-featureModal__comment {
    bottom: 40px;
    left: 200px;
    max-width: 500px;
    width: calc(100% - 300px);
    padding: 20px 20px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-featureModal__comment {
    max-width: 340px;
    width: calc(100% - 120px);
    bottom: 20px;
    left: 110px;
    padding: 15px 10px;
    border-radius: 8px;
  }
}

/* ---------------------------
food
--------------------------- */
.p-food {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-food {
    padding: 0 27.5px;
  }
}
@media screen and (max-width: 767px) {
  .p-food {
    padding-left: 20px;
  }
}
.p-food::before {
  content: "";
  display: block;
  width: 1px;
  position: absolute;
  left: 0;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-food::before {
    height: calc(100% + 160px);
    top: -80px;
  }
}
@media screen and (max-width: 767px) {
  .p-food::before {
    height: calc(100% + 100px);
    top: -50px;
  }
}
@media screen and (min-width: 768px) {
  .p-food__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 90px 75px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-food__list {
    gap: 70px 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
.p-food__list::before, .p-food__list::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-food__list::before, .p-food__list::after {
    width: calc(100% + 90px);
    left: -45px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__list::before, .p-food__list::after {
    width: calc(100% + 20px);
    left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .p-food__list::before {
    top: -35px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__list::before {
    top: -20px;
  }
}
@media screen and (min-width: 768px) {
  .p-food__list::after {
    bottom: -35px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__list::after {
    bottom: -20px;
  }
}
.p-food__item {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 1024px) {
  .p-food__img {
    width: 150px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-food__img {
    width: 120px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__img {
    width: 29.8507462687%;
  }
}
.p-food__contents {
  flex: 1;
}
@media screen and (min-width: 1024px) {
  .p-food__contents {
    margin-left: 25px;
    padding-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-food__contents {
    margin-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__contents {
    margin-left: 15px;
  }
}
.p-food__head {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) {
  .p-food__head {
    gap: 0 20px;
    align-items: baseline;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-food__head {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .p-food__head {
    align-items: baseline;
  }
}
.p-food__heading {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #C0AD49;
  letter-spacing: 0.1em;
  line-height: 1.25;
}
@media screen and (min-width: 768px) {
  .p-food__heading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-food__heading {
    font-size: 1.4285714286rem;
  }
}
.p-food__shop {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.0833333333em;
}
@media screen and (min-width: 1024px) {
  .p-food__shop {
    font-size: 0.75rem;
    padding-bottom: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-food__shop {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 767px) {
  .p-food__shop {
    font-size: 0.7142857143rem;
    margin-left: 8px;
    padding-bottom: 2px;
  }
}
.p-food__text {
  line-height: 1.5714285714;
  letter-spacing: 0.0714285714em;
}
@media screen and (min-width: 768px) {
  .p-food__text {
    font-size: 0.875rem;
    margin-top: 12px;
  }
}
@media screen and (max-width: 767px) {
  .p-food__text {
    font-size: 0.8571428571rem;
    margin-top: 10px;
  }
}

/* ---------------------------
link
--------------------------- */
/* ---------------------------
mv
--------------------------- */
.p-mv {
  position: relative;
}
.p-mv__movie {
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .p-mv__movie {
    width: calc(100% - 170px);
    aspect-ratio: 128/72;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-mv__movie {
    width: calc(100% - 110px);
  }
}
@media screen and (max-width: 767px) {
  .p-mv__movie {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .p-mv__movie video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-mv__movie video {
    width: 100%;
    aspect-ratio: 557/991;
  }
}
.p-mv__copy {
  position: absolute;
  color: #fff;
  font-weight: 500;
  z-index: 5;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.3));
}
@media screen and (min-width: 1024px) {
  .p-mv__copy {
    font-size: 2.5rem;
    line-height: 1.5;
    top: 55px;
    left: 65px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-mv__copy {
    font-size: 1.875rem;
    line-height: 1.5;
    top: 40px;
    left: 40px;
  }
}
@media screen and (max-width: 767px) {
  .p-mv__copy {
    font-size: 1.7142857143rem;
    line-height: 1.6666666667;
    top: 34px;
    left: 31px;
    letter-spacing: 0.0625em;
  }
}
@media screen and (min-width: 768px) {
  .p-mv__heading {
    margin-top: -32px;
    width: 59.8611111111vw;
    margin-left: auto;
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .p-mv__heading {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(90deg) translateY(76%);
    transform-origin: right bottom;
    width: 434px;
  }
}
.p-mv__heading img {
  width: 100%;
}
.p-mv__heading .label {
  font-family: Georgia, Times, "Times New Roman", serif;
  line-height: 1;
  display: block;
  color: #EBEBEC;
}
@media screen and (min-width: 768px) {
  .p-mv__heading .label {
    font-size: min(9.0625rem, 10.0694444444vw);
    /*
    1440pxの場合h
    $a = 170
    $b = 1100
    $c = 458
    labelの幅 881.99px

    */
    letter-spacing: 0.115em;
    margin-right: -22px;
  }
}
@media screen and (min-width: 768px) {
  .p-lowerMv__inner {
    position: relative;
    width: calc(100% - 170px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-lowerMv__inner {
    width: calc(100% - 110px);
  }
}
.p-lowerMv__img {
  width: 100%;
}
.p-lowerMv__img img {
  width: 100%;
}

.p-sectionMv {
  position: relative;
}
.p-sectionMv__img {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-sectionMv__img {
    height: 180px;
  }
}
.p-sectionMv__img img {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-sectionMv__img img {
    object-fit: cover;
    max-width: none;
    height: 100%;
  }
}
.p-sectionMv__heading {
  display: flex;
  flex-direction: column;
  position: absolute;
}
@media screen and (min-width: 768px) {
  .p-sectionMv__heading {
    bottom: 50px;
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-sectionMv__heading {
    bottom: 20px;
    gap: 12px;
  }
}
.p-sectionMv__heading.--left {
  left: 0;
}
.p-sectionMv__heading.--right {
  right: 0;
  align-items: flex-end;
}
.p-sectionMv__heading .en {
  font-family: Georgia, Times, "Times New Roman", serif;
  display: block;
  background-color: rgba(52, 58, 64, 0.9);
  color: #fff;
  letter-spacing: 0.12em;
  line-height: 1.25;
  width: fit-content;
}
@media screen and (min-width: 1024px) {
  .p-sectionMv__heading .en {
    font-size: 3.125rem;
    padding: 15px 28px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-sectionMv__heading .en {
    font-size: 2.625rem;
    padding: 12px 24px;
  }
}
@media screen and (max-width: 767px) {
  .p-sectionMv__heading .en {
    font-size: 1.4285714286rem;
    padding: 6px 16px;
  }
}
.p-sectionMv__heading .ja {
  display: block;
  background-color: rgba(52, 58, 64, 0.9);
  color: #fff;
  letter-spacing: 0.0806451613em;
  line-height: 1.25;
  width: fit-content;
}
@media screen and (min-width: 1024px) {
  .p-sectionMv__heading .ja {
    font-size: 1.9375rem;
    padding: 22px 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-sectionMv__heading .ja {
    font-size: 1.625rem;
    padding: 12px 24px;
  }
}
@media screen and (max-width: 767px) {
  .p-sectionMv__heading .ja {
    font-size: 1.1428571429rem;
    padding: 6px 12px;
  }
}

/* ---------------------------
news
--------------------------- */
.p-newsSection {
  position: relative;
}
.p-newsSection__decoration {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .p-newsSection__decoration {
    margin-top: -32px;
    margin-left: auto;
    width: 59.8611111111vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsSection__decoration {
    margin-top: -24px;
    margin-left: auto;
    width: 70.3125vw;
  }
}
@media screen and (max-width: 767px) {
  .p-newsSection__decoration {
    width: 80vw;
    margin-left: auto;
    margin-top: -5px;
    display: none;
  }
}
.p-newsSection__contents {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .p-newsSection__contents {
    margin-top: -45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsSection__contents {
    margin-top: -20px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsSection__contents {
    padding-top: 40px;
  }
}
.p-newsSection__contents::before, .p-newsSection__contents::after {
  content: "";
  display: block;
  width: 100vw;
  height: 1px;
  position: absolute;
  background-color: rgba(52, 58, 64, 0.1);
  z-index: -3;
}
@media screen and (min-width: 768px) {
  .p-newsSection__contents::before, .p-newsSection__contents::after {
    left: -70px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsSection__contents::before, .p-newsSection__contents::after {
    left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsSection__contents::before {
    top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsSection__contents::before {
    top: 62px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsSection__contents::after {
    bottom: -40px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsSection__contents::after {
    bottom: -30px;
  }
}
.p-newsSection__contents__inner {
  position: relative;
}
.p-newsSection__contents__inner::before {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% + 92px);
  position: absolute;
  top: -5px;
  left: -23px;
  background-color: rgba(52, 58, 64, 0.1);
}
@media screen and (max-width: 767px) {
  .p-newsSection__contents__inner::before {
    height: calc(100% + 50px);
    left: -10px;
  }
}

.p-news__sort {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .p-news__sort {
    gap: 15px;
    justify-content: flex-end;
    margin-top: -65px;
  }
}
@media screen and (max-width: 767px) {
  .p-news__sort {
    gap: 10px;
    justify-content: flex-end;
  }
}
.p-news__sort__item {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-news__sort__item {
    width: 200px;
    height: 54px;
  }
}
@media screen and (max-width: 767px) {
  .p-news__sort__item {
    width: 140px;
    height: 46px;
  }
}
.p-news__sort__button {
  background-color: #343A40;
  color: #fff;
  border: none;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-news__sort__button {
    height: 54px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-news__sort__button {
    height: 46px;
  }
}
.p-news__sort__button::after {
  content: "";
  display: block;
  width: 1px;
  height: 22px;
  position: absolute;
  left: 50%;
  bottom: -13px;
  transform: translateX(-50%);
  background: linear-gradient(to bottom, #fff 9px, #343A40 9px, #343A40 100%);
}
.--open .p-news__sort__button {
  background-color: #fff;
  color: #343A40;
  z-index: 5000;
}
.--open .p-news__sort__button::after {
  background: linear-gradient(to bottom, #343A40 9px, #fff 9px, #fff 100%);
}
.p-news__sort__overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(52, 58, 64, 0.4);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.2s;
  z-index: 2000;
}
.--open .p-news__sort__overlay {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.p-news__sort__list {
  position: absolute;
  left: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s;
}
.--open .p-news__sort__list {
  opacity: 1;
  transform: scaleY(1);
  pointer-events: auto;
  z-index: 5000;
}
.p-news__sort__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #EBEBEC;
  transition: 0.3s ease-out;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .p-news__sort__link {
    font-size: 0.875rem;
    min-height: 69px;
  }
}
@media screen and (max-width: 767px) {
  .p-news__sort__link {
    min-height: 52px;
  }
}
.p-news__sort__link:hover {
  opacity: 1;
  background-color: #C0AD49;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-news__contents {
    margin-top: 70px;
  }
}
@media screen and (max-width: 767px) {
  .p-news__contents {
    margin-top: 32px;
  }
}
@media screen and (min-width: 768px) {
  .p-news__pagination {
    margin-top: 87px;
  }
}
@media screen and (max-width: 767px) {
  .p-news__pagination {
    margin-top: 40px;
  }
}

.p-newsArchive {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-newsArchive {
    display: grid;
    grid-template-columns: repeat(1100, 1fr);
    margin-top: -4px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive {
    padding-left: 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive::before {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    position: absolute;
    background-color: #EBEBEC;
    top: 32px;
    left: -20px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive::after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% + 70px);
    position: absolute;
    top: 0px;
    left: -10px;
    background-color: #EBEBEC;
  }
}
@media screen and (min-width: 768px) {
  .p-newsArchive__head {
    grid-column: 40/356;
    height: fit-content;
    margin-top: -7px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__head {
    grid-column: 1/356;
  }
}
.p-newsArchive__body {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__body {
    grid-column: 459/1101;
    padding-top: 60px;
    padding-bottom: 48px;
    grid-row: 1/2 span;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__body {
    grid-column: 400/1101;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__body {
    margin-top: 30px;
  }
}
.p-newsArchive__body::before, .p-newsArchive__body::after {
  content: "";
  display: block;
  width: 100vw;
  height: 1px;
  position: absolute;
  background-color: #EBEBEC;
  left: -98px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__body::before, .p-newsArchive__body::after {
    left: -30px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__body::before, .p-newsArchive__body::after {
    display: none;
  }
}
.p-newsArchive__body::before {
  top: 0;
}
.p-newsArchive__body::after {
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup {
    margin-top: 32px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup {
    padding-top: 30px;
  }
}
.p-newsArchive__pickup__link:hover .p-newsArchive__pickup__thumbnail img {
  transform: scale(1.05);
}
.p-newsArchive__pickup__head {
  position: relative;
}
.p-newsArchive__pickup__thumbnail {
  overflow: hidden;
}
.p-newsArchive__pickup__thumbnail img {
  width: 100%;
  transition: transform 0.3s ease-out;
}
.p-newsArchive__pickup__category {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #C0AD49;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup__category {
    font-size: 0.75rem;
    line-height: 1.5;
    padding: 3px 12px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup__category {
    font-size: 0.9285714286rem;
    line-height: 1.5;
    padding: 6px 17px;
  }
}
.p-newsArchive__pickup__heading {
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup__heading {
    font-size: 1.25rem;
    margin-top: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__pickup__heading {
    font-size: 1.125rem;
    margin-top: 18px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup__heading {
    letter-spacing: 0.0714285714em;
    line-height: 1.7142857143;
    margin-top: 14px;
  }
}
.p-newsArchive__pickup__meta {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup__meta {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup__meta {
    margin-top: 10px;
  }
}
.p-newsArchive__pickup__date {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup__date {
    font-size: 0.875rem;
    width: 80px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup__date {
    font-size: 0.7857142857rem;
  }
}
.p-newsArchive__pickup__place {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup__place {
    gap: 6px;
    margin-left: 16px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup__place {
    gap: 6px;
    margin-left: 10px;
  }
}
.p-newsArchive__pickup__place__item {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  background-color: #C0AD49;
  color: #fff;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  height: fit-content;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__pickup__place__item {
    font-size: 0.75rem;
    padding: 4px 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__pickup__place__item {
    font-size: 0.7857142857rem;
    padding: 4px 12px;
  }
}
.p-newsArchive__archive {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 10px;
  }
}
.p-newsArchive__archive::before {
  content: "";
  display: block;
  width: 1px;
  position: absolute;
  background-color: #EBEBEC;
}
@media screen and (min-width: 1024px) {
  .p-newsArchive__archive::before {
    height: calc(100% + 198px);
    left: -51px;
    top: -105px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__archive::before {
    height: calc(100% + 170px);
    left: -15px;
    top: -90px;
  }
}
.p-newsArchive__archive__item {
  position: relative;
}
.p-newsArchive__archive__item:not(:last-child)::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background-color: #EBEBEC;
}
@media screen and (min-width: 1024px) {
  .p-newsArchive__archive__item:not(:last-child)::after {
    width: calc(100% + 51px);
    left: -51px;
    bottom: -25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__archive__item:not(:last-child)::after {
    width: calc(100% + 28px);
    left: -28px;
    bottom: -25px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__link {
    display: flex;
    justify-content: space-between;
  }
}
.p-newsArchive__archive__link:hover .p-newsArchive__archive__thumbnail img {
  transform: scale(1.05);
}
.p-newsArchive__archive__head {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__head {
    width: 28.0373831776%;
  }
}
.p-newsArchive__archive__thumbnail {
  overflow: hidden;
}
.p-newsArchive__archive__thumbnail img {
  transition: transform 0.3s ease-out;
}
.p-newsArchive__archive__category {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #C0AD49;
  letter-spacing: 0.1em;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__category {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    font-size: 0.75rem;
    padding: 3px 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__archive__category {
    font-size: 0.6875rem;
    padding: 3px 8px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__category {
    font-size: 0.7857142857rem;
    margin-top: 12px;
  }
}
.p-newsArchive__archive__body {
  flex: 1;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__body {
    margin-left: 40px;
    padding-top: 18px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__archive__body {
    margin-left: 20px;
    padding-top: 8px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__body {
    margin-top: 4px;
  }
}
.p-newsArchive__archive__heading {
  line-height: 1.75;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__heading {
    font-size: 0.8571428571rem;
    line-height: 1.5;
    letter-spacing: 0.0833333333em;
  }
}
.p-newsArchive__archive__meta {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__meta {
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__meta {
    margin-top: 6px;
  }
}
.p-newsArchive__archive__date {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__date {
    font-size: 0.875rem;
    width: 80px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__date {
    font-size: 0.7857142857rem;
  }
}
.p-newsArchive__archive__place {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__place {
    gap: 6px;
    margin-left: 16px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__place {
    gap: 6px;
    margin-left: 10px;
  }
}
.p-newsArchive__archive__place__item {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  background-color: #C0AD49;
  color: #fff;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  height: fit-content;
}
@media screen and (min-width: 768px) {
  .p-newsArchive__archive__place__item {
    font-size: 0.75rem;
    padding: 4px 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__archive__place__item {
    font-size: 0.7857142857rem;
    padding: 4px 12px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsArchive__button {
    grid-column: 40/356;
    grid-row: 2/3;
    height: fit-content;
    margin-top: auto;
    margin-top: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsArchive__button {
    grid-column: 1/356;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive__button {
    margin-top: 29px;
    position: relative;
  }
  .p-newsArchive__button::after {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    background-color: #EBEBEC;
    position: absolute;
    bottom: -40px;
    left: -20px;
  }
}

.p-newsArchive02 {
  display: grid;
}
@media screen and (min-width: 768px) {
  .p-newsArchive02 {
    grid-template-columns: repeat(4, 1fr);
    gap: 50px 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsArchive02 {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 10px;
  }
}

.p-newsPost {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-newsPost {
    padding-top: 98px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost {
    padding-top: 58px;
  }
}
.p-newsPost__contents {
  background-color: #fff;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-newsPost__contents {
    padding: 92px 150px 80px 150px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsPost__contents {
    padding: 62px 40px 56px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__contents {
    padding: 32px 20px 60px 20px;
  }
}
.p-newsPost__contents::before {
  content: "News";
  font-family: Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
  position: absolute;
  color: #fff;
  line-height: 1;
  letter-spacing: 0.06em;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-newsPost__contents::before {
    font-size: 6.25rem;
    top: -85px;
    right: -10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-newsPost__contents::before {
    top: -70px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__contents::before {
    font-size: 2.8571428571rem;
    top: -32px;
    right: -5px;
  }
}
.p-newsPost__meta {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.p-newsPost__date {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.07em;
}
@media screen and (min-width: 768px) {
  .p-newsPost__date {
    font-size: 0.875rem;
    width: 90px;
    padding-top: 1px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__date {
    font-size: 0.8571428571rem;
    width: 70px;
  }
}
.p-newsPost__place {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.p-newsPost__place__item {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  background-color: #C0AD49;
  color: #fff;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  height: fit-content;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-newsPost__place__item {
    font-size: 0.875rem;
    padding: 5px 12px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__place__item {
    font-size: 0.7857142857rem;
    padding: 4px 12px;
  }
}
.p-newsPost__cat {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.p-newsPost__cat__item {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-weight: 400;
  color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-newsPost__cat__item {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__cat__item {
    font-size: 0.8571428571rem;
  }
}
.p-newsPost__title {
  line-height: 1.4375;
  letter-spacing: 0.0625em;
}
@media screen and (min-width: 768px) {
  .p-newsPost__title {
    font-size: 2rem;
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__title {
    font-size: 1.5714285714rem;
    margin-top: 18px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsPost__article {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__article {
    margin-top: 28px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsPost__shop {
    margin-top: 70px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__shop {
    margin-top: 36px;
  }
}
.p-newsPost__nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav {
    margin-top: 90px;
    gap: 70px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav {
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 24px;
  }
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__page {
    width: 120px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__page {
    flex: 1;
  }
}
.p-newsPost__nav__page a {
  display: block;
  position: relative;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__page a {
    font-size: 0.875rem;
  }
}
.p-newsPost__nav__page a::after {
  content: "";
  display: block;
  aspect-ratio: 12/22;
  position: absolute;
  top: 50%;
  background-image: url(../images/common/icon_arrow_01.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__page a::after {
    width: 12px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__page a::after {
    width: 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__page.--prev {
    order: 1;
  }
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__page.--prev a {
    padding-left: 30px;
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__page.--prev a {
    padding-left: 20px;
  }
}
.p-newsPost__nav__page.--prev a::after {
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__page.--next {
    text-align: right;
    order: 2;
  }
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__page.--next a {
    padding-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__page.--next a {
    padding-right: 20px;
  }
}
.p-newsPost__nav__page.--next a::after {
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__back {
    width: 315px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__back {
    width: 100%;
    order: 3;
  }
}
.p-newsPost__nav__back a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border: 1px solid #343A40;
  line-height: 1.5;
  letter-spacing: 0.08em;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__back a {
    max-width: 315px;
    width: 100%;
    min-height: 54px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__back a {
    font-size: 0.8571428571rem;
    min-height: 52px;
    width: calc(100% - 14px);
  }
}
.p-newsPost__nav__back a::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #343A40;
  transition: 0.3s ease-out;
}
@media screen and (min-width: 768px) {
  .p-newsPost__nav__back a::after {
    width: 40px;
    left: -15px;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost__nav__back a::after {
    width: 43px;
    right: -14px;
  }
}

/* ---------------------------
pagination
--------------------------- */
.p-pagination {
  display: flex;
  justify-content: center;
}
.p-pagination .nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .p-pagination .nav-links {
    gap: 16px;
  }
}
@media screen and (max-width: 767px) {
  .p-pagination .nav-links {
    gap: 12px;
  }
}
.p-pagination .nav-links .page-numbers {
  font-family: Georgia, Times, "Times New Roman", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #343A40;
  text-align: center;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .p-pagination .nav-links .page-numbers {
    width: 48px;
    height: 48px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-pagination .nav-links .page-numbers {
    width: 40px;
    height: 40px;
  }
}
.p-pagination .nav-links .page-numbers:hover, .p-pagination .nav-links .page-numbers.current {
  background-color: #343A40;
  color: #fff;
}
.p-pagination .nav-links .page-numbers.next, .p-pagination .nav-links .page-numbers.prev {
  border: none;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-pagination .nav-links .page-numbers.next, .p-pagination .nav-links .page-numbers.prev {
    width: 26px;
  }
}
@media screen and (max-width: 767px) {
  .p-pagination .nav-links .page-numbers.next, .p-pagination .nav-links .page-numbers.prev {
    width: 24px;
  }
}
.p-pagination .nav-links .page-numbers.next::after, .p-pagination .nav-links .page-numbers.prev::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 4px), -50%) rotate(135deg);
  transform-origin: center;
  border-bottom: 1px solid #343A40;
  border-right: 1px solid #343A40;
}
@media screen and (min-width: 768px) {
  .p-pagination .nav-links .page-numbers.next::after, .p-pagination .nav-links .page-numbers.prev::after {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 767px) {
  .p-pagination .nav-links .page-numbers.next::after, .p-pagination .nav-links .page-numbers.prev::after {
    width: 14px;
    height: 14px;
  }
}
.p-pagination .nav-links .page-numbers.next:hover, .p-pagination .nav-links .page-numbers.prev:hover {
  background-color: transparent;
}
.p-pagination .nav-links .page-numbers.next {
  transform: rotate(180deg);
}

/* ---------------------------
shop
--------------------------- */
.p-shopIndex {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopIndex {
    padding-top: 180px;
    padding-bottom: 225px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopIndex {
    padding-top: 75px;
  }
}
.p-shopIndex::before {
  content: "";
  display: block;
  height: calc(100% + 600px);
  position: absolute;
  top: 0;
  border-radius: 9999px 9999px 0 0;
  background-color: #EBEBEC;
  background-image: url(../images/common/img_texture_01.webp);
  background-size: 2967px auto;
  background-repeat: repeat;
  z-index: 0;
}
@media screen and (min-width: 768px) {
  .p-shopIndex::before {
    width: 231.0416666667%;
    left: -40%;
  }
}
@media screen and (max-width: 767px) {
  .p-shopIndex::before {
    width: 291.6666666667%;
    left: -59.7222222222%;
  }
}

.p-shopMv {
  position: relative;
  z-index: 5;
}
.p-shopMv__inner {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopMv__inner {
    width: calc(100% - 170px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopMv__inner {
    width: calc(100% - 110px);
  }
}
@media screen and (max-width: 767px) {
  .p-shopMv__inner {
    width: 100%;
  }
}
.p-shopMv__img {
  width: 100%;
}
.p-shopMv__img img {
  width: 100%;
}
.p-shopMv__movie {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-shopMv__movie {
    aspect-ratio: 128/72;
  }
}
@media screen and (max-width: 767px) {
  .p-shopMv__movie {
    aspect-ratio: 557/991;
  }
}
.p-shopMv__movie iframe {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .p-shopMv__movie iframe {
    aspect-ratio: 128/72;
  }
}
@media screen and (max-width: 767px) {
  .p-shopMv__movie iframe {
    aspect-ratio: 557/991;
  }
}
.p-shopMv__heading {
  width: 100%;
  background-color: rgba(52, 58, 64, 0.6);
  color: #fff;
  line-height: 1.25;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .p-shopMv__heading {
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    padding: 33px 48px 55px;
    bottom: 0;
    left: 0;
    position: absolute;
    text-align: right;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopMv__heading {
    font-size: 1.75rem;
    padding: 24px 30px 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopMv__heading {
    font-size: 1.2857142857rem;
    letter-spacing: 0.1em;
    padding: 16px 20px 16px 20px;
  }
}
.p-shopMv__heading::before, .p-shopMv__heading::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 767px) {
  .p-shopMv__heading::before, .p-shopMv__heading::after {
    display: none;
  }
}
.p-shopMv__heading::before {
  width: 100%;
  height: 1px;
  right: 0;
  bottom: 30px;
}
.p-shopMv__heading::after {
  width: 1px;
  height: 100%;
  right: 30px;
  top: 0;
}

.p-shopArchive {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-shopArchive {
    gap: 90px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopArchive {
    gap: 42px;
  }
}

.p-shopInformation {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopInformation {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .p-shopArchive__item:nth-child(even) .p-shopInformation {
    flex-direction: row-reverse;
  }
}
.p-shopInformation__img {
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__img {
    width: 50%;
  }
}
.p-shopInformation__img img {
  width: 100%;
  transition: 0.3s;
}
.p-shopInformation__img:hover img {
  opacity: 0.7;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__contents {
    width: 43.4545454545%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopInformation__contents {
    width: 47.2727272727%;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__contents {
    margin-top: 20px;
  }
}
.p-shopInformation__heading {
  border-bottom: 1px solid rgba(52, 58, 64, 0.4);
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__heading {
    font-size: 1.5rem;
    padding-bottom: 26px;
    letter-spacing: 0.09em;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopInformation__heading {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__heading {
    font-size: 1.1428571429rem;
    padding-bottom: 18px;
    letter-spacing: 0.0625em;
  }
}
.p-shopInformation__information {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__information {
    margin-top: 35px;
    gap: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopInformation__information {
    margin-top: 28px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__information {
    margin-top: 18px;
    gap: 18px;
  }
}
.p-shopInformation__information dl {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-shopInformation__information dl {
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(52, 58, 64, 0.4);
  }
}
@media screen and (min-width: 768px) {
  .p-shopInformation__information dl dt {
    letter-spacing: 0.09em;
    line-height: 1.5;
    width: 90px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__information dl dt {
    letter-spacing: 0.0714285714em;
    line-height: 1.5714285714;
    width: 75px;
  }
}
.p-shopInformation__information dl dd {
  flex: 1;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__information dl dd {
    letter-spacing: 0.09em;
    line-height: 1.5;
    margin-left: 5px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__information dl dd {
    letter-spacing: 0.0714285714em;
    line-height: 1.5714285714;
    margin-left: 5px;
  }
}
.p-shopInformation__facility {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__facility {
    margin-top: 36px;
    gap: 15px 25px;
    margin-left: -5px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__facility {
    margin-top: 24px;
    gap: 12px;
  }
}
.p-shopInformation__facility__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__facility__item {
    width: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__facility__item {
    width: 48px;
  }
}
.p-shopInformation__facility__item figcaption {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-shopInformation__facility__item figcaption {
    font-size: 0.625rem;
    margin-top: 4px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__facility__item figcaption {
    font-size: 0.7142857143rem;
    margin-top: 4px;
  }
}
.p-shopInformation__button {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 768px) {
  .p-shopInformation__button {
    gap: 65px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopInformation__button {
    gap: 35px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation__button {
    margin-top: 22px;
    gap: 10px;
  }
}

.p-shopInformation02 {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02 {
    padding-top: 60px;
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02 {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}
.p-shopInformation02::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #343A40;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02::before {
    width: min(100vw - 20px, 1120px + (100vw - 1020px) / 2);
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02::before {
    width: 100%;
  }
}
.p-shopInformation02__wrapper {
  position: relative;
}
.p-shopInformation02__wrapper::before, .p-shopInformation02__wrapper::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__wrapper::before, .p-shopInformation02__wrapper::after {
    width: min(1192px, 100% - 40px);
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__wrapper::before, .p-shopInformation02__wrapper::after {
    width: 100%;
    left: 0px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__wrapper::before {
    top: 45px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__wrapper::before {
    top: 26px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__wrapper::after {
    bottom: -65px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__wrapper::after {
    bottom: -24px;
  }
}
.p-shopInformation02__inner {
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__inner {
    padding-left: 20px;
    padding-right: 30px;
  }
}
.p-shopInformation02__inner::after {
  content: "";
  display: block;
  width: 1px;
  position: absolute;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__inner::after {
    height: calc(100% + 130px);
    bottom: -130px;
    right: -20px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__inner::after {
    height: calc(100% + 110px);
    top: -40px;
    right: 10px;
  }
}
.p-shopInformation02__heading {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #C0AD49;
  position: relative;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__heading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__heading {
    font-size: 1.7142857143rem;
  }
}
.p-shopInformation02__row {
  display: flex;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__row {
    justify-content: space-between;
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__row {
    flex-direction: column;
    margin-top: 12px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__contents {
    width: 42.6530612245%;
  }
}
.p-shopInformation02__time {
  line-height: 1.6;
  color: #fff;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__time {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__time {
    font-size: 1.1428571429rem;
  }
}
.p-shopInformation02__information {
  line-height: 1.6;
  color: #fff;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__information {
    font-size: 1.125rem;
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__information {
    margin-top: 24px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__image {
    width: 53.4693877551%;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__image {
    margin-top: 32px;
  }
}
.p-shopInformation02__facility {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__facility {
    margin-top: 36px;
    gap: 15px 25px;
    margin-left: -5px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__facility {
    margin-top: 24px;
    gap: 12px;
  }
}
.p-shopInformation02__facility__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__facility__item {
    width: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__facility__item {
    width: 48px;
  }
}
.p-shopInformation02__facility__item figcaption {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  text-align: center;
  line-height: 1.5;
}
@media screen and (min-width: 768px) {
  .p-shopInformation02__facility__item figcaption {
    font-size: 0.625rem;
    margin-top: 4px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopInformation02__facility__item figcaption {
    font-size: 0.7142857143rem;
    margin-top: 4px;
  }
}

.p-shopList {
  background-color: #343A40;
  position: relative;
  z-index: 5;
}
@media screen and (min-width: 768px) {
  .p-shopList {
    padding: 85px 95px 76px 124px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopList {
    padding: 50px 30px 50px 60px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList {
    padding: 32px 24px 90px;
  }
}
.p-shopList::before {
  content: "";
  display: block;
  width: 1px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 768px) {
  .p-shopList::before {
    height: calc(100% - 145px);
    left: 102px;
    top: 73px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopList::before {
    height: calc(100% - 60px);
    left: 30px;
    top: 40px;
  }
}
.p-shopList__heading {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-shopList__heading {
    font-size: 1.75rem;
    padding-left: 25px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopList__heading {
    padding-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__heading {
    font-size: 1.2857142857rem;
  }
}
.p-shopList__list {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopList__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 52px 30px;
    padding-top: 83px;
    padding-bottom: 92px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopList__list {
    padding-top: 32px;
    padding-bottom: 45px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 28px;
  }
}
.p-shopList__list::before, .p-shopList__list::after {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
}
@media screen and (min-width: 768px) {
  .p-shopList__list::before, .p-shopList__list::after {
    width: calc(100% + 108px);
    left: -68px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-shopList__list::before, .p-shopList__list::after {
    width: calc(100% + 30px);
    left: -45px;
  }
}
.p-shopList__list::before {
  top: -10px;
}
.p-shopList__list::after {
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .p-shopList__list__item {
    display: grid;
    grid-template-columns: repeat(430, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 768px) {
  .p-shopList__list__img {
    grid-column: 1/151;
    grid-row: 1/2 span;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list__img {
    width: 44.2190669371%;
    padding-top: 4px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopList__list__contents {
    grid-column: 170/431;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list__contents {
    margin-left: 10px;
    flex: 1;
  }
}
.p-shopList__list__heading {
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-shopList__list__heading {
    line-height: 1.5;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list__heading {
    line-height: 1.7142857143;
    letter-spacing: 0.0714285714em;
  }
}
.p-shopList__list__address {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  line-height: 1.5;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-shopList__list__address {
    font-size: 0.875rem;
    margin-top: 18px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list__address {
    font-size: 0.7142857143rem;
    line-height: 1.9;
    letter-spacing: 0.1em;
    margin-top: 4px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopList__list__button {
    margin-top: 16px;
    grid-column: 170/431;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList__list__button {
    width: 100%;
    margin-top: 12px;
  }
}

@media screen and (min-width: 768px) {
  .p-shopList02 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopList02 {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.p-shopCard {
  background-color: #343A40;
}
@media screen and (min-width: 768px) {
  .p-shopCard {
    padding: 30px 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard {
    padding: 24px 20px;
  }
}
.p-shopCard__row {
  display: grid;
}
@media screen and (min-width: 768px) {
  .p-shopCard__row {
    grid-template-columns: repeat(483, 1fr);
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.p-shopCard__heading {
  color: #fff;
  line-height: 1.7777777778;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-shopCard__heading {
    font-size: 1.125rem;
    grid-column: 175/484;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__heading {
    width: 100%;
    font-size: 1.1428571429rem;
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopCard__contents {
    display: flex;
    flex-direction: column;
    grid-column: 175/484;
    margin-top: 11px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__contents {
    flex: 1;
    margin-left: 10px;
  }
}
.p-shopCard__address {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-shopCard__address {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__address {
    font-size: 0.8571428571rem;
  }
}
.p-shopCard__linkList {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-shopCard__linkList {
    padding-top: 15px;
    margin-top: auto;
    gap: 4px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__linkList {
    margin-top: 10px;
    gap: 4px;
  }
}
.p-shopCard__link {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #C0AD49;
  word-break: break-all;
}
@media screen and (min-width: 768px) {
  .p-shopCard__link {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__link {
    font-size: 0.8571428571rem;
  }
}
@media screen and (min-width: 768px) {
  .p-shopCard__image {
    grid-column: 1/151;
    grid-row: 1/2 span;
    padding-top: 5px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__image {
    width: 33.8983050847%;
  }
}
.p-shopCard__text {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  line-height: 1.7;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-shopCard__text {
    margin-top: 24px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .p-shopCard__text {
    margin-top: 18px;
    font-size: 0.8571428571rem;
  }
}

.p-shopColumn {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-shopColumn {
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .p-shopColumn {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .p-shopColumn__contents {
    width: 49.5454545455%;
    padding-top: 11px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopColumn__image {
    width: 44%;
  }
}
@media screen and (max-width: 767px) {
  .p-shopColumn__image {
    margin-top: 32px;
  }
}
.p-shopColumn__heading .en {
  font-family: Georgia, Times, "Times New Roman", serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-shopColumn__heading .en {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-shopColumn__heading .en {
    font-size: 1.4285714286rem;
  }
}
.p-shopColumn__heading .ja {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.0833333333em;
  display: block;
}
@media screen and (min-width: 768px) {
  .p-shopColumn__heading .ja {
    font-size: 0.75rem;
    margin-top: 8px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopColumn__heading .ja {
    font-size: 0.7142857143rem;
    margin-top: 6px;
  }
}
@media screen and (min-width: 768px) {
  .p-shopColumn__text {
    margin-top: 28px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopColumn__text {
    margin-top: 20px;
  }
}
.p-shopColumn__text p {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.0714285714em;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-shopColumn__text p {
    font-size: 0.875rem;
  }
}
.p-shopColumn__link {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.0714285714em;
  font-weight: 400;
  display: inline-block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-shopColumn__link {
    font-size: 0.875rem;
    margin-top: 32px;
  }
}
@media screen and (max-width: 767px) {
  .p-shopColumn__link {
    margin-top: 14px;
  }
}
.p-shopColumn__link::after {
  content: "　＞";
}

/* ---------------------------
sns
--------------------------- */
.p-sns {
  background-image: url(../images/common/bg_sns_01.webp);
  background-image: image-set(url(../images/common/bg_sns_01.webp) 1x, url(../images/common/bg_sns_01@2x.webp) 2x);
  background-size: cover;
  background-position: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-sns {
    padding-top: 160px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns {
    padding-top: 47px;
  }
}
.p-sns__row {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .p-sns__row {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .p-sns__contents {
    width: 32.3636363636%;
    padding-bottom: 24px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__contents {
    padding-left: 25px;
    padding-right: 20px;
  }
}
.p-sns__list {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-sns__list {
    gap: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__list {
    gap: 20px;
  }
}
.p-sns__list__item {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .p-sns__list__item .icon {
    width: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__list__item .icon {
    width: 45px;
  }
}
.p-sns__list__item .label {
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.11em;
}
@media screen and (min-width: 768px) {
  .p-sns__list__item .label {
    font-size: 1.5rem;
    margin-left: 12px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__list__item .label {
    letter-spacing: 0.1em;
    font-size: 1.1428571429rem;
    margin-left: 8px;
  }
}
.p-sns__text {
  color: #fff;
  letter-spacing: 0.12em;
  line-height: 1.625;
}
@media screen and (min-width: 768px) {
  .p-sns__text {
    margin-top: 34px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__text {
    margin-top: 20px;
    line-height: 1.5714285714;
    letter-spacing: 0.0714285714em;
  }
}
.p-sns__buttons {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-sns__buttons {
    margin-top: 30px;
    gap: 15px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__buttons {
    margin-top: 30px;
    gap: 15px;
  }
}
.p-sns__images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (min-width: 768px) {
  .p-sns__images {
    width: 55.2727272727%;
    gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-sns__images {
    margin-top: 37px;
    padding: 0 10px;
    gap: 10px;
    margin-bottom: -26px;
  }
}
.p-sns__images__item {
  display: flex;
  align-items: flex-end;
}
.p-sns__images__item img {
  width: 100%;
}

/* ---------------------------
story
--------------------------- */
.p-story {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-story {
    padding-top: 114px;
    padding-bottom: 114px;
  }
}
@media screen and (max-width: 767px) {
  .p-story {
    padding-top: 80px;
    padding-bottom: 75px;
  }
}
.p-story::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 9999px 9999px 0 0;
}
@media screen and (min-width: 768px) {
  .p-story::before {
    width: 231.0416666667%;
    min-height: 231.0416666667vw;
    left: -82.9166666667%;
  }
}
@media screen and (max-width: 767px) {
  .p-story::before {
    width: 291.6666666667%;
    left: -47.619047619%;
    aspect-ratio: 1/1;
  }
}
.p-story::after {
  content: "";
  display: block;
  aspect-ratio: 899/383;
  position: absolute;
  background-image: url(../images/index/decoration_story_01.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .p-story::after {
    width: 62.4305555556%;
    top: 0;
    right: 0;
    transform: translate(4.6718576196%, -24.2819843342%);
  }
}
@media screen and (max-width: 767px) {
  .p-story__heading {
    display: flex;
    justify-content: flex-end;
  }
}
.p-story__row {
  display: flex;
}
@media screen and (min-width: 768px) {
  .p-story__row {
    margin-top: 18px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__row {
    flex-direction: column;
    margin-top: -35px;
  }
}
.p-story__images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: fit-content;
}
@media screen and (min-width: 768px) {
  .p-story__images {
    width: 46.9090909091%;
    gap: 20px 20px;
    margin-top: 34px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__images {
    width: calc(100% + 40px);
    gap: 10px 10px;
    margin-left: -20px;
  }
}
.p-story__image {
  position: relative;
  height: fit-content;
}
.p-story__image:first-child {
  grid-column: 1/2;
  grid-row: 1/2 span;
}
.p-story__image:first-child img {
  filter: grayscale(1);
}
.p-story__image:nth-child(2) {
  grid-column: 1/2;
}
.p-story__image:nth-child(2) img {
  filter: grayscale(1);
}
.p-story__image:last-child {
  grid-column: 2/3;
}
@media screen and (min-width: 768px) {
  .p-story__image:last-child {
    margin-top: -120px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__image:last-child {
    margin-top: -80px;
  }
}
.p-story__contents {
  flex: 1;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-story__contents {
    max-width: 465px;
    margin-left: 45px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__contents {
    margin-top: 40px;
    padding: 0 24px;
  }
}
.p-story__contents::before {
  content: "";
  display: block;
  aspect-ratio: 679/639;
  position: absolute;
  background-image: url(../images/common/icon_communication_01_primary.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .p-story__contents::before {
    max-width: 679px;
    width: 146.0215053763%;
    top: 90px;
    left: 4px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__contents::before {
    max-width: 300px;
    width: 110.2941176471%;
    top: 84px;
    left: 25px;
  }
}
.p-story__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-story__logo .image {
    max-width: 270px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__logo .image {
    max-width: 158px;
    margin-right: 10px;
  }
}
.p-story__logo .label {
  text-align: center;
  letter-spacing: 0.3em;
}
@media screen and (min-width: 768px) {
  .p-story__logo .label {
    font-size: 1.125rem;
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__logo .label {
    font-size: 0.7857142857rem;
    margin-top: 18px;
  }
}
.p-story__renewal {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-story__renewal {
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__renewal {
    margin-top: 30px;
  }
}
.p-story__renewal img {
  width: 100%;
}
.p-story__text {
  position: relative;
  line-height: 1.5;
  letter-spacing: 0.125em;
}
@media screen and (min-width: 768px) {
  .p-story__text {
    margin-top: 55px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__text {
    margin-top: 32px;
    line-height: 1.5714285714;
    letter-spacing: 0.0714285714em;
  }
}
.p-story__button {
  display: flex;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-story__button {
    margin-top: 42px;
  }
}
@media screen and (max-width: 767px) {
  .p-story__button {
    margin-top: 30px;
  }
}

.p-story02 {
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-story02 {
    padding-top: 80px;
    padding-bottom: 160px;
  }
}
@media screen and (max-width: 767px) {
  .p-story02 {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}
.p-story02::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #343A40;
  background-size: 2967px auto;
  background-repeat: repeat;
  border-radius: 9999px 9999px 0 0;
  z-index: -5;
}
@media screen and (min-width: 768px) {
  .p-story02::before {
    width: 231.0416666667%;
    height: calc(100% + 600px);
    top: -100px;
    left: -33.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .p-story02::before {
    width: 291.6666666667%;
    height: calc(100% + 400px);
    top: -40px;
    left: -47.619047619%;
  }
}
.p-story02__inner {
  position: relative;
}
.p-story02__row {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .p-story02__row {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-story02__row {
    padding-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__row {
    flex-direction: column-reverse;
    margin-top: -100px;
  }
}
.p-story02__body {
  flex-shrink: 0;
}
@media screen and (min-width: 1024px) {
  .p-story02__body {
    width: 54.7272727273%;
    padding-top: 105px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-story02__body {
    width: 58.1818181818%;
    padding-top: 75px;
  }
}
.p-story02__map {
  flex-shrink: 0;
}
@media screen and (min-width: 1024px) {
  .p-story02__map {
    width: 116.0909090909%;
    margin-left: 58px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-story02__map {
    max-width: 960px;
    width: 109.0909090909%;
    margin-left: 24px;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__map {
    max-width: 435px;
    width: 130%;
    transform: translateX(20%);
    margin-left: auto;
  }
}
.p-story02__heading {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: rgba(255, 255, 255, 0.4);
  line-height: 1.25;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .p-story02__heading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__heading {
    font-size: 1.7142857143rem;
  }
}
.p-story02__contents {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .p-story02__contents {
    gap: 70px;
    margin-top: 35px;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__contents {
    gap: 40px;
    margin-top: 20px;
  }
}
.p-story02__section__heading {
  color: #fff;
  line-height: 1.625;
}
@media screen and (min-width: 1024px) {
  .p-story02__section__heading {
    font-size: 2rem;
    letter-spacing: 0.0625em;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .p-story02__section__heading {
    font-size: 1.75rem;
    letter-spacing: 0.0714285714em;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__section__heading {
    font-size: 1.2857142857rem;
    letter-spacing: 0.0555555556em;
  }
}
.p-story02__section__text {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
@media screen and (min-width: 768px) {
  .p-story02__section__text {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__section__text {
    margin-top: 16px;
  }
}
.p-story02__section__text p {
  color: #fff;
}
@media screen and (min-width: 768px) {
  .p-story02__section__text p {
    letter-spacing: 0.1111111111em;
    font-size: 1.125rem;
    line-height: 2.7777777778;
  }
}
@media screen and (max-width: 767px) {
  .p-story02__section__text p {
    letter-spacing: 0.0714285714em;
    line-height: 2.2;
  }
}

/* ---------------------------
wysiwyg
--------------------------- */
.p-wysiwyg > :first-child {
  margin-block-start: 0;
}
.p-wysiwyg > * {
  margin-block-start: 1.6em;
}
.p-wysiwyg p {
  line-height: 2;
  letter-spacing: 0.125em;
}
.p-wysiwyg p > a {
  color: #C0AD49;
}
.p-wysiwyg a {
  text-decoration: underline;
}
.p-wysiwyg a:hover {
  text-decoration: none;
}
.p-wysiwyg h2 {
  line-height: 1.4285714286;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg h2 {
    font-size: 1.75rem;
    padding-bottom: 31px;
    letter-spacing: 0.0714285714em;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg h2 {
    font-size: 1.5714285714rem;
    padding-bottom: 21px;
    letter-spacing: 0.0454545455em;
  }
}
.p-wysiwyg h2::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg h2::before {
    background: linear-gradient(to right, #C0AD49 108px, #DDDAC9 108px);
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg h2::before {
    background: linear-gradient(to right, #C0AD49 62px, #DDDAC9 62px);
  }
}
.p-wysiwyg h3 {
  line-height: 1.4285714286;
  position: relative;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg h3 {
    font-size: 1.5rem;
    padding-left: 24px;
    letter-spacing: 0.0833333333em;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg h3 {
    font-size: 1.4285714286rem;
    padding-left: 18px;
    letter-spacing: 0.05em;
  }
}
.p-wysiwyg h3::before {
  content: "";
  display: block;
  width: 2px;
  position: absolute;
  left: 0;
  background-color: #C0AD49;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg h3::before {
    height: 30px;
    top: 1px;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg h3::before {
    height: 26px;
    top: 1px;
  }
}
.p-wysiwyg h4 {
  font-weight: bold;
  line-height: 1.4285714286;
  border-bottom: 2px solid #DDDAC9;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg h4 {
    font-size: 1.25rem;
    padding-bottom: 20px;
    letter-spacing: 0.1em;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg h4 {
    font-size: 1.2857142857rem;
    padding-bottom: 14px;
    letter-spacing: 0.0555555556em;
  }
}
.p-wysiwyg h5,
.p-wysiwyg h6 {
  font-weight: bold;
  line-height: 1.4285714286;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg h5,
  .p-wysiwyg h6 {
    font-size: 1.125rem;
    letter-spacing: 0.1111111111em;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg h5,
  .p-wysiwyg h6 {
    font-size: 1.1428571429rem;
    letter-spacing: 0.0625em;
  }
}
.p-wysiwyg ul > li {
  position: relative;
  padding-left: 1.1em;
  letter-spacing: 0.125em;
  line-height: 2;
}
.p-wysiwyg ul > li::before {
  content: "・";
  position: absolute;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg ul > li::before {
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg ul > li::before {
    top: 0;
    left: 0;
  }
}
.p-wysiwyg ol {
  counter-reset: 0;
}
.p-wysiwyg ol > li {
  counter-increment: number;
  position: relative;
  padding-left: 1.4em;
  letter-spacing: 0.125em;
  line-height: 2;
}
.p-wysiwyg ol > li::before {
  content: counter(number) ".";
  position: absolute;
  top: 0;
  left: 0.25em;
}
.p-wysiwyg .wp-block-image.aligncenter {
  margin-inline: auto;
}
.p-wysiwyg .wp-block-image img {
  max-width: 100%;
}
.p-wysiwyg .wp-block-image figcaption {
  font-family: "Noto Sans JP", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin-top: 1.3em;
  letter-spacing: 1px;
  text-align: left;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg .wp-block-image figcaption {
    font-size: 0.875rem;
  }
}
.p-wysiwyg .wp-block-table {
  position: relative;
}
.p-wysiwyg .wp-block-table table {
  border: 1px solid #343A40;
}
.p-wysiwyg .wp-block-table table th,
.p-wysiwyg .wp-block-table table td {
  border: none;
  vertical-align: top;
  letter-spacing: 2px;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg .wp-block-table table th,
  .p-wysiwyg .wp-block-table table td {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg .wp-block-table table th,
  .p-wysiwyg .wp-block-table table td {
    padding: 12px 10px;
  }
}
.p-wysiwyg .wp-block-table table thead {
  border-bottom: 1px solid #343A40;
  color: #fff;
}
.p-wysiwyg .wp-block-table table thead tr th {
  background-color: #C0AD49;
}
.p-wysiwyg .wp-block-table table thead tr th:not(:last-child) {
  border-right: 1px solid #343A40;
}
.p-wysiwyg .wp-block-table table tbody tr:not(:last-child) {
  border-bottom: 1px solid #343A40;
}
.p-wysiwyg .wp-block-table table tbody tr th {
  border-right: 1px solid #343A40;
}
.p-wysiwyg .wp-block-table table tbody tr td:first-child {
  background-color: #EFEFEF;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg .wp-block-table table tbody tr td:first-child {
    font-size: 1.125rem;
  }
}
.p-wysiwyg .wp-block-table table tbody tr td:not(:last-child) {
  border-right: 1px solid #343A40;
}
.p-wysiwyg .wp-block-buttons {
  gap: 0.875em;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg .wp-block-buttons .wp-block-button {
    flex: 1;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg .wp-block-buttons .wp-block-button {
    width: 100%;
  }
}
.p-wysiwyg .wp-block-buttons .wp-block-button__link {
  background-color: #343A40;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  position: relative;
  text-align: center;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 2px;
  border: none;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  margin-inline: auto;
}
@media screen and (min-width: 768px) {
  .p-wysiwyg .wp-block-buttons .wp-block-button__link {
    max-width: 600px;
    width: 100%;
    min-height: 54px;
    padding: 10px 50px 10px 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-wysiwyg .wp-block-buttons .wp-block-button__link {
    width: 100%;
    min-height: 46px;
    padding: 8px 36px 8px 36px;
  }
}

/* ---------------------------
u-border
--------------------------- */
.u-border--b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.u-border--gray {
  border-color: #cccccc;
}

.u-c--base {
  color: #343A40 !important;
}
.u-c--lightGray {
  color: #EFEFEF !important;
}
.u-c--primary {
  color: #C0AD49 !important;
}
.u-c--secondary {
  color: #FFDD00 !important;
}
.u-c--wh {
  color: #fff !important;
}

.u-bg--base {
  background-color: #343A40 !important;
}
.u-bg--wh {
  background-color: #fff !important;
}
.u-bg--bg {
  background-color: #f2f0e9 !important;
}
.u-bg--primary {
  background-color: #C0AD49 !important;
}
.u-bg--secondary {
  background-color: #FFDD00 !important;
}
.u-bg--quaternary {
  background-color: #D6E4EA !important;
}
.u-bg--gray {
  background-color: #EBEBEC;
}

/* ---------------------------
u-display
--------------------------- */
@media screen and (min-width: 768px) {
  .u-display--pc {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .u-display--pc {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .u-display--sp {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .u-display--sp {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .u-display--large {
    display: block;
  }
}
@media screen and (max-width: 1023px) {
  .u-display--large {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .u-display--tb {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-display--tb {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .u-display--tb {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .u-display--tbsp {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .u-display--tbsp {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .u-display--tbnone {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-display--tbnone {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .u-display--tbnone {
    display: block;
  }
}

/* ---------------------------
m
--------------------------- */
.u-valign--t {
  vertical-align: top !important;
}

.u-valign--m {
  vertical-align: middle !important;
}

.u-valign--b {
  vertical-align: bottom !important;
}

@media screen and (min-width: 768px) {
  .u-mt--0 {
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--0--tb {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--0--sp {
    margin-top: 0px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--0 {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--0--tb {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--0--sp {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--0 {
    padding-top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--0--tb {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--0--sp {
    padding-top: 0px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--0 {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--0--tb {
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--0--sp {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--1 {
    margin-top: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--1--tb {
    margin-top: 2px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--1--sp {
    margin-top: 2px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--1 {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--1--tb {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--1--sp {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--1 {
    padding-top: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--1--tb {
    padding-top: 2px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--1--sp {
    padding-top: 2px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--1 {
    padding-bottom: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--1--tb {
    padding-bottom: 2px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--1--sp {
    padding-bottom: 2px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--2 {
    margin-top: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--2--tb {
    margin-top: 4px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--2--sp {
    margin-top: 4px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--2 {
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--2--tb {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--2--sp {
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--2 {
    padding-top: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--2--tb {
    padding-top: 4px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--2--sp {
    padding-top: 4px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--2 {
    padding-bottom: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--2--tb {
    padding-bottom: 4px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--2--sp {
    padding-bottom: 4px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--3 {
    margin-top: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--3--tb {
    margin-top: 6px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--3--sp {
    margin-top: 6px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--3 {
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--3--tb {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--3--sp {
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--3 {
    padding-top: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--3--tb {
    padding-top: 6px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--3--sp {
    padding-top: 6px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--3 {
    padding-bottom: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--3--tb {
    padding-bottom: 6px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--3--sp {
    padding-bottom: 6px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--4 {
    margin-top: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--4--tb {
    margin-top: 8px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--4--sp {
    margin-top: 8px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--4 {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--4--tb {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--4--sp {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--4 {
    padding-top: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--4--tb {
    padding-top: 8px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--4--sp {
    padding-top: 8px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--4 {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--4--tb {
    padding-bottom: 8px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--4--sp {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--5 {
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--5--tb {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--5--sp {
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--5 {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--5--tb {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--5--sp {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--5 {
    padding-top: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--5--tb {
    padding-top: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--5--sp {
    padding-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--5 {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--5--tb {
    padding-bottom: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--5--sp {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--6 {
    margin-top: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--6--tb {
    margin-top: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--6--sp {
    margin-top: 12px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--6 {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--6--tb {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--6--sp {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--6 {
    padding-top: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--6--tb {
    padding-top: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--6--sp {
    padding-top: 12px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--6 {
    padding-bottom: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--6--tb {
    padding-bottom: 12px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--6--sp {
    padding-bottom: 12px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--7 {
    margin-top: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--7--tb {
    margin-top: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--7--sp {
    margin-top: 14px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--7 {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--7--tb {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--7--sp {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--7 {
    padding-top: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--7--tb {
    padding-top: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--7--sp {
    padding-top: 14px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--7 {
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--7--tb {
    padding-bottom: 14px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--7--sp {
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--8 {
    margin-top: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--8--tb {
    margin-top: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--8--sp {
    margin-top: 16px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--8 {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--8--tb {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--8--sp {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--8 {
    padding-top: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--8--tb {
    padding-top: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--8--sp {
    padding-top: 16px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--8 {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--8--tb {
    padding-bottom: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--8--sp {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--9 {
    margin-top: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--9--tb {
    margin-top: 18px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--9--sp {
    margin-top: 18px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--9 {
    margin-bottom: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--9--tb {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--9--sp {
    margin-bottom: 18px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--9 {
    padding-top: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--9--tb {
    padding-top: 18px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--9--sp {
    padding-top: 18px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--9 {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--9--tb {
    padding-bottom: 18px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--9--sp {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--10 {
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--10--tb {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--10--sp {
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--10 {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--10--tb {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--10--sp {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--10 {
    padding-top: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--10--tb {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--10--sp {
    padding-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--10 {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--10--tb {
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--10--sp {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--11 {
    margin-top: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--11--tb {
    margin-top: 22px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--11--sp {
    margin-top: 22px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--11 {
    margin-bottom: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--11--tb {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--11--sp {
    margin-bottom: 22px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--11 {
    padding-top: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--11--tb {
    padding-top: 22px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--11--sp {
    padding-top: 22px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--11 {
    padding-bottom: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--11--tb {
    padding-bottom: 22px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--11--sp {
    padding-bottom: 22px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--12 {
    margin-top: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--12--tb {
    margin-top: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--12--sp {
    margin-top: 24px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--12 {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--12--tb {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--12--sp {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--12 {
    padding-top: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--12--tb {
    padding-top: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--12--sp {
    padding-top: 24px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--12 {
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--12--tb {
    padding-bottom: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--12--sp {
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--13 {
    margin-top: 26px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--13--tb {
    margin-top: 26px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--13--sp {
    margin-top: 26px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--13 {
    margin-bottom: 26px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--13--tb {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--13--sp {
    margin-bottom: 26px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--13 {
    padding-top: 26px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--13--tb {
    padding-top: 26px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--13--sp {
    padding-top: 26px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--13 {
    padding-bottom: 26px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--13--tb {
    padding-bottom: 26px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--13--sp {
    padding-bottom: 26px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--14 {
    margin-top: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--14--tb {
    margin-top: 28px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--14--sp {
    margin-top: 28px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--14 {
    margin-bottom: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--14--tb {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--14--sp {
    margin-bottom: 28px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--14 {
    padding-top: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--14--tb {
    padding-top: 28px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--14--sp {
    padding-top: 28px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--14 {
    padding-bottom: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--14--tb {
    padding-bottom: 28px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--14--sp {
    padding-bottom: 28px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--15 {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--15--tb {
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--15--sp {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--15 {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--15--tb {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--15--sp {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--15 {
    padding-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--15--tb {
    padding-top: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--15--sp {
    padding-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--15 {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--15--tb {
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--15--sp {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--16 {
    margin-top: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--16--tb {
    margin-top: 32px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--16--sp {
    margin-top: 32px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--16 {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--16--tb {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--16--sp {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--16 {
    padding-top: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--16--tb {
    padding-top: 32px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--16--sp {
    padding-top: 32px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--16 {
    padding-bottom: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--16--tb {
    padding-bottom: 32px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--16--sp {
    padding-bottom: 32px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--17 {
    margin-top: 34px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--17--tb {
    margin-top: 34px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--17--sp {
    margin-top: 34px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--17 {
    margin-bottom: 34px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--17--tb {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--17--sp {
    margin-bottom: 34px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--17 {
    padding-top: 34px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--17--tb {
    padding-top: 34px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--17--sp {
    padding-top: 34px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--17 {
    padding-bottom: 34px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--17--tb {
    padding-bottom: 34px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--17--sp {
    padding-bottom: 34px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--18 {
    margin-top: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--18--tb {
    margin-top: 36px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--18--sp {
    margin-top: 36px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--18 {
    margin-bottom: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--18--tb {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--18--sp {
    margin-bottom: 36px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--18 {
    padding-top: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--18--tb {
    padding-top: 36px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--18--sp {
    padding-top: 36px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--18 {
    padding-bottom: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--18--tb {
    padding-bottom: 36px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--18--sp {
    padding-bottom: 36px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--19 {
    margin-top: 38px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--19--tb {
    margin-top: 38px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--19--sp {
    margin-top: 38px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--19 {
    margin-bottom: 38px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--19--tb {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--19--sp {
    margin-bottom: 38px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--19 {
    padding-top: 38px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--19--tb {
    padding-top: 38px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--19--sp {
    padding-top: 38px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--19 {
    padding-bottom: 38px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--19--tb {
    padding-bottom: 38px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--19--sp {
    padding-bottom: 38px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--20 {
    margin-top: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--20--tb {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--20--sp {
    margin-top: 40px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--20 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--20--tb {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--20--sp {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--20 {
    padding-top: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--20--tb {
    padding-top: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--20--sp {
    padding-top: 40px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--20 {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--20--tb {
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--20--sp {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--21 {
    margin-top: 42px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--21--tb {
    margin-top: 42px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--21--sp {
    margin-top: 42px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--21 {
    margin-bottom: 42px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--21--tb {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--21--sp {
    margin-bottom: 42px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--21 {
    padding-top: 42px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--21--tb {
    padding-top: 42px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--21--sp {
    padding-top: 42px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--21 {
    padding-bottom: 42px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--21--tb {
    padding-bottom: 42px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--21--sp {
    padding-bottom: 42px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--22 {
    margin-top: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--22--tb {
    margin-top: 44px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--22--sp {
    margin-top: 44px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--22 {
    margin-bottom: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--22--tb {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--22--sp {
    margin-bottom: 44px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--22 {
    padding-top: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--22--tb {
    padding-top: 44px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--22--sp {
    padding-top: 44px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--22 {
    padding-bottom: 44px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--22--tb {
    padding-bottom: 44px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--22--sp {
    padding-bottom: 44px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--23 {
    margin-top: 46px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--23--tb {
    margin-top: 46px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--23--sp {
    margin-top: 46px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--23 {
    margin-bottom: 46px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--23--tb {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--23--sp {
    margin-bottom: 46px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--23 {
    padding-top: 46px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--23--tb {
    padding-top: 46px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--23--sp {
    padding-top: 46px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--23 {
    padding-bottom: 46px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--23--tb {
    padding-bottom: 46px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--23--sp {
    padding-bottom: 46px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--24 {
    margin-top: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--24--tb {
    margin-top: 48px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--24--sp {
    margin-top: 48px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--24 {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--24--tb {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--24--sp {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--24 {
    padding-top: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--24--tb {
    padding-top: 48px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--24--sp {
    padding-top: 48px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--24 {
    padding-bottom: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--24--tb {
    padding-bottom: 48px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--24--sp {
    padding-bottom: 48px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--25 {
    margin-top: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--25--tb {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--25--sp {
    margin-top: 50px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--25 {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--25--tb {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--25--sp {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--25 {
    padding-top: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--25--tb {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--25--sp {
    padding-top: 50px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--25 {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--25--tb {
    padding-bottom: 50px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--25--sp {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--26 {
    margin-top: 52px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--26--tb {
    margin-top: 52px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--26--sp {
    margin-top: 52px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--26 {
    margin-bottom: 52px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--26--tb {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--26--sp {
    margin-bottom: 52px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--26 {
    padding-top: 52px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--26--tb {
    padding-top: 52px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--26--sp {
    padding-top: 52px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--26 {
    padding-bottom: 52px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--26--tb {
    padding-bottom: 52px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--26--sp {
    padding-bottom: 52px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--27 {
    margin-top: 54px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--27--tb {
    margin-top: 54px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--27--sp {
    margin-top: 54px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--27 {
    margin-bottom: 54px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--27--tb {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--27--sp {
    margin-bottom: 54px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--27 {
    padding-top: 54px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--27--tb {
    padding-top: 54px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--27--sp {
    padding-top: 54px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--27 {
    padding-bottom: 54px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--27--tb {
    padding-bottom: 54px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--27--sp {
    padding-bottom: 54px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--28 {
    margin-top: 56px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--28--tb {
    margin-top: 56px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--28--sp {
    margin-top: 56px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--28 {
    margin-bottom: 56px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--28--tb {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--28--sp {
    margin-bottom: 56px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--28 {
    padding-top: 56px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--28--tb {
    padding-top: 56px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--28--sp {
    padding-top: 56px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--28 {
    padding-bottom: 56px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--28--tb {
    padding-bottom: 56px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--28--sp {
    padding-bottom: 56px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--29 {
    margin-top: 58px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--29--tb {
    margin-top: 58px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--29--sp {
    margin-top: 58px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--29 {
    margin-bottom: 58px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--29--tb {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--29--sp {
    margin-bottom: 58px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--29 {
    padding-top: 58px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--29--tb {
    padding-top: 58px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--29--sp {
    padding-top: 58px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--29 {
    padding-bottom: 58px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--29--tb {
    padding-bottom: 58px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--29--sp {
    padding-bottom: 58px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--30 {
    margin-top: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--30--tb {
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--30--sp {
    margin-top: 60px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--30 {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--30--tb {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--30--sp {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--30 {
    padding-top: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--30--tb {
    padding-top: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--30--sp {
    padding-top: 60px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--30 {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--30--tb {
    padding-bottom: 60px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--30--sp {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--31 {
    margin-top: 62px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--31--tb {
    margin-top: 62px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--31--sp {
    margin-top: 62px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--31 {
    margin-bottom: 62px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--31--tb {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--31--sp {
    margin-bottom: 62px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--31 {
    padding-top: 62px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--31--tb {
    padding-top: 62px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--31--sp {
    padding-top: 62px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--31 {
    padding-bottom: 62px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--31--tb {
    padding-bottom: 62px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--31--sp {
    padding-bottom: 62px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--32 {
    margin-top: 64px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--32--tb {
    margin-top: 64px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--32--sp {
    margin-top: 64px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--32 {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--32--tb {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--32--sp {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--32 {
    padding-top: 64px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--32--tb {
    padding-top: 64px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--32--sp {
    padding-top: 64px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--32 {
    padding-bottom: 64px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--32--tb {
    padding-bottom: 64px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--32--sp {
    padding-bottom: 64px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--33 {
    margin-top: 66px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--33--tb {
    margin-top: 66px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--33--sp {
    margin-top: 66px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--33 {
    margin-bottom: 66px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--33--tb {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--33--sp {
    margin-bottom: 66px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--33 {
    padding-top: 66px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--33--tb {
    padding-top: 66px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--33--sp {
    padding-top: 66px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--33 {
    padding-bottom: 66px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--33--tb {
    padding-bottom: 66px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--33--sp {
    padding-bottom: 66px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--34 {
    margin-top: 68px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--34--tb {
    margin-top: 68px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--34--sp {
    margin-top: 68px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--34 {
    margin-bottom: 68px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--34--tb {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--34--sp {
    margin-bottom: 68px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--34 {
    padding-top: 68px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--34--tb {
    padding-top: 68px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--34--sp {
    padding-top: 68px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--34 {
    padding-bottom: 68px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--34--tb {
    padding-bottom: 68px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--34--sp {
    padding-bottom: 68px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--35 {
    margin-top: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--35--tb {
    margin-top: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--35--sp {
    margin-top: 70px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--35 {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--35--tb {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--35--sp {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--35 {
    padding-top: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--35--tb {
    padding-top: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--35--sp {
    padding-top: 70px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--35 {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--35--tb {
    padding-bottom: 70px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--35--sp {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--36 {
    margin-top: 72px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--36--tb {
    margin-top: 72px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--36--sp {
    margin-top: 72px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--36 {
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--36--tb {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--36--sp {
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--36 {
    padding-top: 72px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--36--tb {
    padding-top: 72px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--36--sp {
    padding-top: 72px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--36 {
    padding-bottom: 72px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--36--tb {
    padding-bottom: 72px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--36--sp {
    padding-bottom: 72px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--37 {
    margin-top: 74px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--37--tb {
    margin-top: 74px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--37--sp {
    margin-top: 74px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--37 {
    margin-bottom: 74px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--37--tb {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--37--sp {
    margin-bottom: 74px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--37 {
    padding-top: 74px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--37--tb {
    padding-top: 74px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--37--sp {
    padding-top: 74px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--37 {
    padding-bottom: 74px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--37--tb {
    padding-bottom: 74px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--37--sp {
    padding-bottom: 74px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--38 {
    margin-top: 76px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--38--tb {
    margin-top: 76px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--38--sp {
    margin-top: 76px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--38 {
    margin-bottom: 76px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--38--tb {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--38--sp {
    margin-bottom: 76px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--38 {
    padding-top: 76px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--38--tb {
    padding-top: 76px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--38--sp {
    padding-top: 76px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--38 {
    padding-bottom: 76px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--38--tb {
    padding-bottom: 76px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--38--sp {
    padding-bottom: 76px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--39 {
    margin-top: 78px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--39--tb {
    margin-top: 78px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--39--sp {
    margin-top: 78px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--39 {
    margin-bottom: 78px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--39--tb {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--39--sp {
    margin-bottom: 78px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--39 {
    padding-top: 78px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--39--tb {
    padding-top: 78px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--39--sp {
    padding-top: 78px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--39 {
    padding-bottom: 78px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--39--tb {
    padding-bottom: 78px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--39--sp {
    padding-bottom: 78px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--40 {
    margin-top: 80px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--40--tb {
    margin-top: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--40--sp {
    margin-top: 80px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--40 {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--40--tb {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--40--sp {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--40 {
    padding-top: 80px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--40--tb {
    padding-top: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--40--sp {
    padding-top: 80px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--40 {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--40--tb {
    padding-bottom: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--40--sp {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--41 {
    margin-top: 82px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--41--tb {
    margin-top: 82px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--41--sp {
    margin-top: 82px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--41 {
    margin-bottom: 82px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--41--tb {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--41--sp {
    margin-bottom: 82px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--41 {
    padding-top: 82px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--41--tb {
    padding-top: 82px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--41--sp {
    padding-top: 82px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--41 {
    padding-bottom: 82px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--41--tb {
    padding-bottom: 82px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--41--sp {
    padding-bottom: 82px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--42 {
    margin-top: 84px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--42--tb {
    margin-top: 84px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--42--sp {
    margin-top: 84px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--42 {
    margin-bottom: 84px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--42--tb {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--42--sp {
    margin-bottom: 84px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--42 {
    padding-top: 84px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--42--tb {
    padding-top: 84px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--42--sp {
    padding-top: 84px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--42 {
    padding-bottom: 84px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--42--tb {
    padding-bottom: 84px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--42--sp {
    padding-bottom: 84px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--43 {
    margin-top: 86px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--43--tb {
    margin-top: 86px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--43--sp {
    margin-top: 86px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--43 {
    margin-bottom: 86px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--43--tb {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--43--sp {
    margin-bottom: 86px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--43 {
    padding-top: 86px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--43--tb {
    padding-top: 86px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--43--sp {
    padding-top: 86px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--43 {
    padding-bottom: 86px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--43--tb {
    padding-bottom: 86px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--43--sp {
    padding-bottom: 86px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--44 {
    margin-top: 88px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--44--tb {
    margin-top: 88px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--44--sp {
    margin-top: 88px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--44 {
    margin-bottom: 88px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--44--tb {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--44--sp {
    margin-bottom: 88px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--44 {
    padding-top: 88px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--44--tb {
    padding-top: 88px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--44--sp {
    padding-top: 88px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--44 {
    padding-bottom: 88px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--44--tb {
    padding-bottom: 88px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--44--sp {
    padding-bottom: 88px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--45 {
    margin-top: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--45--tb {
    margin-top: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--45--sp {
    margin-top: 90px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--45 {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--45--tb {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--45--sp {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--45 {
    padding-top: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--45--tb {
    padding-top: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--45--sp {
    padding-top: 90px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--45 {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--45--tb {
    padding-bottom: 90px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--45--sp {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--46 {
    margin-top: 92px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--46--tb {
    margin-top: 92px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--46--sp {
    margin-top: 92px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--46 {
    margin-bottom: 92px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--46--tb {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--46--sp {
    margin-bottom: 92px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--46 {
    padding-top: 92px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--46--tb {
    padding-top: 92px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--46--sp {
    padding-top: 92px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--46 {
    padding-bottom: 92px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--46--tb {
    padding-bottom: 92px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--46--sp {
    padding-bottom: 92px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--47 {
    margin-top: 94px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--47--tb {
    margin-top: 94px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--47--sp {
    margin-top: 94px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--47 {
    margin-bottom: 94px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--47--tb {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--47--sp {
    margin-bottom: 94px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--47 {
    padding-top: 94px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--47--tb {
    padding-top: 94px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--47--sp {
    padding-top: 94px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--47 {
    padding-bottom: 94px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--47--tb {
    padding-bottom: 94px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--47--sp {
    padding-bottom: 94px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--48 {
    margin-top: 96px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--48--tb {
    margin-top: 96px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--48--sp {
    margin-top: 96px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--48 {
    margin-bottom: 96px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--48--tb {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--48--sp {
    margin-bottom: 96px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--48 {
    padding-top: 96px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--48--tb {
    padding-top: 96px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--48--sp {
    padding-top: 96px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--48 {
    padding-bottom: 96px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--48--tb {
    padding-bottom: 96px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--48--sp {
    padding-bottom: 96px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--49 {
    margin-top: 98px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--49--tb {
    margin-top: 98px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--49--sp {
    margin-top: 98px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--49 {
    margin-bottom: 98px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--49--tb {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--49--sp {
    margin-bottom: 98px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--49 {
    padding-top: 98px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--49--tb {
    padding-top: 98px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--49--sp {
    padding-top: 98px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--49 {
    padding-bottom: 98px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--49--tb {
    padding-bottom: 98px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--49--sp {
    padding-bottom: 98px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--50 {
    margin-top: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--50--tb {
    margin-top: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--50--sp {
    margin-top: 100px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--50 {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--50--tb {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--50--sp {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--50 {
    padding-top: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--50--tb {
    padding-top: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--50--sp {
    padding-top: 100px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--50 {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--50--tb {
    padding-bottom: 100px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--50--sp {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--51 {
    margin-top: 102px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--51--tb {
    margin-top: 102px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--51--sp {
    margin-top: 102px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--51 {
    margin-bottom: 102px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--51--tb {
    margin-bottom: 102px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--51--sp {
    margin-bottom: 102px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--51 {
    padding-top: 102px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--51--tb {
    padding-top: 102px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--51--sp {
    padding-top: 102px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--51 {
    padding-bottom: 102px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--51--tb {
    padding-bottom: 102px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--51--sp {
    padding-bottom: 102px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--52 {
    margin-top: 104px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--52--tb {
    margin-top: 104px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--52--sp {
    margin-top: 104px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--52 {
    margin-bottom: 104px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--52--tb {
    margin-bottom: 104px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--52--sp {
    margin-bottom: 104px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--52 {
    padding-top: 104px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--52--tb {
    padding-top: 104px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--52--sp {
    padding-top: 104px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--52 {
    padding-bottom: 104px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--52--tb {
    padding-bottom: 104px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--52--sp {
    padding-bottom: 104px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--53 {
    margin-top: 106px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--53--tb {
    margin-top: 106px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--53--sp {
    margin-top: 106px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--53 {
    margin-bottom: 106px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--53--tb {
    margin-bottom: 106px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--53--sp {
    margin-bottom: 106px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--53 {
    padding-top: 106px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--53--tb {
    padding-top: 106px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--53--sp {
    padding-top: 106px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--53 {
    padding-bottom: 106px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--53--tb {
    padding-bottom: 106px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--53--sp {
    padding-bottom: 106px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--54 {
    margin-top: 108px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--54--tb {
    margin-top: 108px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--54--sp {
    margin-top: 108px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--54 {
    margin-bottom: 108px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--54--tb {
    margin-bottom: 108px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--54--sp {
    margin-bottom: 108px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--54 {
    padding-top: 108px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--54--tb {
    padding-top: 108px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--54--sp {
    padding-top: 108px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--54 {
    padding-bottom: 108px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--54--tb {
    padding-bottom: 108px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--54--sp {
    padding-bottom: 108px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--55 {
    margin-top: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--55--tb {
    margin-top: 110px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--55--sp {
    margin-top: 110px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--55 {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--55--tb {
    margin-bottom: 110px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--55--sp {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--55 {
    padding-top: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--55--tb {
    padding-top: 110px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--55--sp {
    padding-top: 110px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--55 {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--55--tb {
    padding-bottom: 110px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--55--sp {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--56 {
    margin-top: 112px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--56--tb {
    margin-top: 112px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--56--sp {
    margin-top: 112px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--56 {
    margin-bottom: 112px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--56--tb {
    margin-bottom: 112px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--56--sp {
    margin-bottom: 112px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--56 {
    padding-top: 112px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--56--tb {
    padding-top: 112px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--56--sp {
    padding-top: 112px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--56 {
    padding-bottom: 112px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--56--tb {
    padding-bottom: 112px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--56--sp {
    padding-bottom: 112px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--57 {
    margin-top: 114px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--57--tb {
    margin-top: 114px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--57--sp {
    margin-top: 114px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--57 {
    margin-bottom: 114px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--57--tb {
    margin-bottom: 114px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--57--sp {
    margin-bottom: 114px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--57 {
    padding-top: 114px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--57--tb {
    padding-top: 114px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--57--sp {
    padding-top: 114px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--57 {
    padding-bottom: 114px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--57--tb {
    padding-bottom: 114px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--57--sp {
    padding-bottom: 114px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--58 {
    margin-top: 116px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--58--tb {
    margin-top: 116px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--58--sp {
    margin-top: 116px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--58 {
    margin-bottom: 116px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--58--tb {
    margin-bottom: 116px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--58--sp {
    margin-bottom: 116px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--58 {
    padding-top: 116px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--58--tb {
    padding-top: 116px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--58--sp {
    padding-top: 116px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--58 {
    padding-bottom: 116px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--58--tb {
    padding-bottom: 116px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--58--sp {
    padding-bottom: 116px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--59 {
    margin-top: 118px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--59--tb {
    margin-top: 118px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--59--sp {
    margin-top: 118px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--59 {
    margin-bottom: 118px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--59--tb {
    margin-bottom: 118px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--59--sp {
    margin-bottom: 118px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--59 {
    padding-top: 118px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--59--tb {
    padding-top: 118px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--59--sp {
    padding-top: 118px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--59 {
    padding-bottom: 118px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--59--tb {
    padding-bottom: 118px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--59--sp {
    padding-bottom: 118px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--60 {
    margin-top: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--60--tb {
    margin-top: 120px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--60--sp {
    margin-top: 120px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--60 {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--60--tb {
    margin-bottom: 120px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--60--sp {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--60 {
    padding-top: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--60--tb {
    padding-top: 120px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--60--sp {
    padding-top: 120px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--60 {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--60--tb {
    padding-bottom: 120px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--60--sp {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--61 {
    margin-top: 122px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--61--tb {
    margin-top: 122px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--61--sp {
    margin-top: 122px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--61 {
    margin-bottom: 122px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--61--tb {
    margin-bottom: 122px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--61--sp {
    margin-bottom: 122px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--61 {
    padding-top: 122px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--61--tb {
    padding-top: 122px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--61--sp {
    padding-top: 122px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--61 {
    padding-bottom: 122px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--61--tb {
    padding-bottom: 122px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--61--sp {
    padding-bottom: 122px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--62 {
    margin-top: 124px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--62--tb {
    margin-top: 124px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--62--sp {
    margin-top: 124px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--62 {
    margin-bottom: 124px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--62--tb {
    margin-bottom: 124px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--62--sp {
    margin-bottom: 124px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--62 {
    padding-top: 124px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--62--tb {
    padding-top: 124px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--62--sp {
    padding-top: 124px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--62 {
    padding-bottom: 124px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--62--tb {
    padding-bottom: 124px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--62--sp {
    padding-bottom: 124px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--63 {
    margin-top: 126px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--63--tb {
    margin-top: 126px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--63--sp {
    margin-top: 126px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--63 {
    margin-bottom: 126px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--63--tb {
    margin-bottom: 126px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--63--sp {
    margin-bottom: 126px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--63 {
    padding-top: 126px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--63--tb {
    padding-top: 126px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--63--sp {
    padding-top: 126px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--63 {
    padding-bottom: 126px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--63--tb {
    padding-bottom: 126px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--63--sp {
    padding-bottom: 126px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--64 {
    margin-top: 128px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--64--tb {
    margin-top: 128px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--64--sp {
    margin-top: 128px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--64 {
    margin-bottom: 128px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--64--tb {
    margin-bottom: 128px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--64--sp {
    margin-bottom: 128px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--64 {
    padding-top: 128px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--64--tb {
    padding-top: 128px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--64--sp {
    padding-top: 128px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--64 {
    padding-bottom: 128px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--64--tb {
    padding-bottom: 128px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--64--sp {
    padding-bottom: 128px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--65 {
    margin-top: 130px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--65--tb {
    margin-top: 130px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--65--sp {
    margin-top: 130px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--65 {
    margin-bottom: 130px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--65--tb {
    margin-bottom: 130px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--65--sp {
    margin-bottom: 130px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--65 {
    padding-top: 130px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--65--tb {
    padding-top: 130px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--65--sp {
    padding-top: 130px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--65 {
    padding-bottom: 130px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--65--tb {
    padding-bottom: 130px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--65--sp {
    padding-bottom: 130px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--66 {
    margin-top: 132px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--66--tb {
    margin-top: 132px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--66--sp {
    margin-top: 132px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--66 {
    margin-bottom: 132px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--66--tb {
    margin-bottom: 132px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--66--sp {
    margin-bottom: 132px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--66 {
    padding-top: 132px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--66--tb {
    padding-top: 132px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--66--sp {
    padding-top: 132px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--66 {
    padding-bottom: 132px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--66--tb {
    padding-bottom: 132px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--66--sp {
    padding-bottom: 132px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--67 {
    margin-top: 134px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--67--tb {
    margin-top: 134px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--67--sp {
    margin-top: 134px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--67 {
    margin-bottom: 134px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--67--tb {
    margin-bottom: 134px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--67--sp {
    margin-bottom: 134px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--67 {
    padding-top: 134px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--67--tb {
    padding-top: 134px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--67--sp {
    padding-top: 134px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--67 {
    padding-bottom: 134px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--67--tb {
    padding-bottom: 134px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--67--sp {
    padding-bottom: 134px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--68 {
    margin-top: 136px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--68--tb {
    margin-top: 136px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--68--sp {
    margin-top: 136px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--68 {
    margin-bottom: 136px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--68--tb {
    margin-bottom: 136px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--68--sp {
    margin-bottom: 136px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--68 {
    padding-top: 136px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--68--tb {
    padding-top: 136px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--68--sp {
    padding-top: 136px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--68 {
    padding-bottom: 136px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--68--tb {
    padding-bottom: 136px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--68--sp {
    padding-bottom: 136px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--69 {
    margin-top: 138px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--69--tb {
    margin-top: 138px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--69--sp {
    margin-top: 138px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--69 {
    margin-bottom: 138px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--69--tb {
    margin-bottom: 138px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--69--sp {
    margin-bottom: 138px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--69 {
    padding-top: 138px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--69--tb {
    padding-top: 138px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--69--sp {
    padding-top: 138px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--69 {
    padding-bottom: 138px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--69--tb {
    padding-bottom: 138px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--69--sp {
    padding-bottom: 138px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--70 {
    margin-top: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--70--tb {
    margin-top: 140px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--70--sp {
    margin-top: 140px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--70 {
    margin-bottom: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--70--tb {
    margin-bottom: 140px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--70--sp {
    margin-bottom: 140px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--70 {
    padding-top: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--70--tb {
    padding-top: 140px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--70--sp {
    padding-top: 140px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--70 {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--70--tb {
    padding-bottom: 140px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--70--sp {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--71 {
    margin-top: 142px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--71--tb {
    margin-top: 142px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--71--sp {
    margin-top: 142px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--71 {
    margin-bottom: 142px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--71--tb {
    margin-bottom: 142px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--71--sp {
    margin-bottom: 142px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--71 {
    padding-top: 142px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--71--tb {
    padding-top: 142px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--71--sp {
    padding-top: 142px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--71 {
    padding-bottom: 142px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--71--tb {
    padding-bottom: 142px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--71--sp {
    padding-bottom: 142px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--72 {
    margin-top: 144px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--72--tb {
    margin-top: 144px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--72--sp {
    margin-top: 144px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--72 {
    margin-bottom: 144px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--72--tb {
    margin-bottom: 144px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--72--sp {
    margin-bottom: 144px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--72 {
    padding-top: 144px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--72--tb {
    padding-top: 144px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--72--sp {
    padding-top: 144px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--72 {
    padding-bottom: 144px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--72--tb {
    padding-bottom: 144px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--72--sp {
    padding-bottom: 144px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--73 {
    margin-top: 146px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--73--tb {
    margin-top: 146px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--73--sp {
    margin-top: 146px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--73 {
    margin-bottom: 146px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--73--tb {
    margin-bottom: 146px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--73--sp {
    margin-bottom: 146px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--73 {
    padding-top: 146px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--73--tb {
    padding-top: 146px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--73--sp {
    padding-top: 146px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--73 {
    padding-bottom: 146px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--73--tb {
    padding-bottom: 146px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--73--sp {
    padding-bottom: 146px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--74 {
    margin-top: 148px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--74--tb {
    margin-top: 148px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--74--sp {
    margin-top: 148px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--74 {
    margin-bottom: 148px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--74--tb {
    margin-bottom: 148px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--74--sp {
    margin-bottom: 148px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--74 {
    padding-top: 148px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--74--tb {
    padding-top: 148px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--74--sp {
    padding-top: 148px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--74 {
    padding-bottom: 148px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--74--tb {
    padding-bottom: 148px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--74--sp {
    padding-bottom: 148px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--75 {
    margin-top: 150px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--75--tb {
    margin-top: 150px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--75--sp {
    margin-top: 150px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--75 {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--75--tb {
    margin-bottom: 150px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--75--sp {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--75 {
    padding-top: 150px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--75--tb {
    padding-top: 150px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--75--sp {
    padding-top: 150px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--75 {
    padding-bottom: 150px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--75--tb {
    padding-bottom: 150px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--75--sp {
    padding-bottom: 150px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--76 {
    margin-top: 152px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--76--tb {
    margin-top: 152px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--76--sp {
    margin-top: 152px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--76 {
    margin-bottom: 152px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--76--tb {
    margin-bottom: 152px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--76--sp {
    margin-bottom: 152px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--76 {
    padding-top: 152px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--76--tb {
    padding-top: 152px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--76--sp {
    padding-top: 152px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--76 {
    padding-bottom: 152px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--76--tb {
    padding-bottom: 152px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--76--sp {
    padding-bottom: 152px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--77 {
    margin-top: 154px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--77--tb {
    margin-top: 154px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--77--sp {
    margin-top: 154px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--77 {
    margin-bottom: 154px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--77--tb {
    margin-bottom: 154px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--77--sp {
    margin-bottom: 154px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--77 {
    padding-top: 154px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--77--tb {
    padding-top: 154px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--77--sp {
    padding-top: 154px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--77 {
    padding-bottom: 154px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--77--tb {
    padding-bottom: 154px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--77--sp {
    padding-bottom: 154px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--78 {
    margin-top: 156px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--78--tb {
    margin-top: 156px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--78--sp {
    margin-top: 156px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--78 {
    margin-bottom: 156px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--78--tb {
    margin-bottom: 156px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--78--sp {
    margin-bottom: 156px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--78 {
    padding-top: 156px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--78--tb {
    padding-top: 156px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--78--sp {
    padding-top: 156px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--78 {
    padding-bottom: 156px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--78--tb {
    padding-bottom: 156px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--78--sp {
    padding-bottom: 156px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--79 {
    margin-top: 158px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--79--tb {
    margin-top: 158px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--79--sp {
    margin-top: 158px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--79 {
    margin-bottom: 158px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--79--tb {
    margin-bottom: 158px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--79--sp {
    margin-bottom: 158px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--79 {
    padding-top: 158px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--79--tb {
    padding-top: 158px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--79--sp {
    padding-top: 158px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--79 {
    padding-bottom: 158px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--79--tb {
    padding-bottom: 158px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--79--sp {
    padding-bottom: 158px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--80 {
    margin-top: 160px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--80--tb {
    margin-top: 160px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--80--sp {
    margin-top: 160px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--80 {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--80--tb {
    margin-bottom: 160px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--80--sp {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--80 {
    padding-top: 160px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--80--tb {
    padding-top: 160px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--80--sp {
    padding-top: 160px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--80 {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--80--tb {
    padding-bottom: 160px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--80--sp {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--81 {
    margin-top: 162px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--81--tb {
    margin-top: 162px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--81--sp {
    margin-top: 162px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--81 {
    margin-bottom: 162px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--81--tb {
    margin-bottom: 162px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--81--sp {
    margin-bottom: 162px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--81 {
    padding-top: 162px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--81--tb {
    padding-top: 162px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--81--sp {
    padding-top: 162px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--81 {
    padding-bottom: 162px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--81--tb {
    padding-bottom: 162px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--81--sp {
    padding-bottom: 162px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--82 {
    margin-top: 164px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--82--tb {
    margin-top: 164px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--82--sp {
    margin-top: 164px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--82 {
    margin-bottom: 164px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--82--tb {
    margin-bottom: 164px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--82--sp {
    margin-bottom: 164px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--82 {
    padding-top: 164px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--82--tb {
    padding-top: 164px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--82--sp {
    padding-top: 164px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--82 {
    padding-bottom: 164px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--82--tb {
    padding-bottom: 164px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--82--sp {
    padding-bottom: 164px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--83 {
    margin-top: 166px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--83--tb {
    margin-top: 166px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--83--sp {
    margin-top: 166px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--83 {
    margin-bottom: 166px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--83--tb {
    margin-bottom: 166px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--83--sp {
    margin-bottom: 166px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--83 {
    padding-top: 166px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--83--tb {
    padding-top: 166px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--83--sp {
    padding-top: 166px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--83 {
    padding-bottom: 166px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--83--tb {
    padding-bottom: 166px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--83--sp {
    padding-bottom: 166px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--84 {
    margin-top: 168px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--84--tb {
    margin-top: 168px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--84--sp {
    margin-top: 168px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--84 {
    margin-bottom: 168px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--84--tb {
    margin-bottom: 168px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--84--sp {
    margin-bottom: 168px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--84 {
    padding-top: 168px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--84--tb {
    padding-top: 168px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--84--sp {
    padding-top: 168px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--84 {
    padding-bottom: 168px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--84--tb {
    padding-bottom: 168px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--84--sp {
    padding-bottom: 168px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--85 {
    margin-top: 170px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--85--tb {
    margin-top: 170px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--85--sp {
    margin-top: 170px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--85 {
    margin-bottom: 170px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--85--tb {
    margin-bottom: 170px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--85--sp {
    margin-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--85 {
    padding-top: 170px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--85--tb {
    padding-top: 170px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--85--sp {
    padding-top: 170px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--85 {
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--85--tb {
    padding-bottom: 170px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--85--sp {
    padding-bottom: 170px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--86 {
    margin-top: 172px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--86--tb {
    margin-top: 172px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--86--sp {
    margin-top: 172px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--86 {
    margin-bottom: 172px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--86--tb {
    margin-bottom: 172px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--86--sp {
    margin-bottom: 172px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--86 {
    padding-top: 172px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--86--tb {
    padding-top: 172px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--86--sp {
    padding-top: 172px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--86 {
    padding-bottom: 172px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--86--tb {
    padding-bottom: 172px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--86--sp {
    padding-bottom: 172px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--87 {
    margin-top: 174px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--87--tb {
    margin-top: 174px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--87--sp {
    margin-top: 174px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--87 {
    margin-bottom: 174px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--87--tb {
    margin-bottom: 174px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--87--sp {
    margin-bottom: 174px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--87 {
    padding-top: 174px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--87--tb {
    padding-top: 174px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--87--sp {
    padding-top: 174px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--87 {
    padding-bottom: 174px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--87--tb {
    padding-bottom: 174px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--87--sp {
    padding-bottom: 174px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--88 {
    margin-top: 176px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--88--tb {
    margin-top: 176px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--88--sp {
    margin-top: 176px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--88 {
    margin-bottom: 176px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--88--tb {
    margin-bottom: 176px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--88--sp {
    margin-bottom: 176px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--88 {
    padding-top: 176px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--88--tb {
    padding-top: 176px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--88--sp {
    padding-top: 176px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--88 {
    padding-bottom: 176px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--88--tb {
    padding-bottom: 176px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--88--sp {
    padding-bottom: 176px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--89 {
    margin-top: 178px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--89--tb {
    margin-top: 178px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--89--sp {
    margin-top: 178px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--89 {
    margin-bottom: 178px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--89--tb {
    margin-bottom: 178px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--89--sp {
    margin-bottom: 178px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--89 {
    padding-top: 178px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--89--tb {
    padding-top: 178px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--89--sp {
    padding-top: 178px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--89 {
    padding-bottom: 178px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--89--tb {
    padding-bottom: 178px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--89--sp {
    padding-bottom: 178px;
  }
}

@media screen and (min-width: 768px) {
  .u-mt--90 {
    margin-top: 180px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mt--90--tb {
    margin-top: 180px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mt--90--sp {
    margin-top: 180px;
  }
}

@media screen and (min-width: 768px) {
  .u-mb--90 {
    margin-bottom: 180px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-mb--90--tb {
    margin-bottom: 180px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-mb--90--sp {
    margin-bottom: 180px;
  }
}

@media screen and (min-width: 768px) {
  .u-pt--90 {
    padding-top: 180px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pt--90--tb {
    padding-top: 180px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pt--90--sp {
    padding-top: 180px;
  }
}

@media screen and (min-width: 768px) {
  .u-pb--90 {
    padding-bottom: 180px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-pb--90--tb {
    padding-bottom: 180px !important;
  }
}

@media screen and (max-width: 767px) {
  .u-pb--90--sp {
    padding-bottom: 180px;
  }
}

.u-position--relative {
  position: relative;
}

/* ---------------------------
text
--------------------------- */
.u-fwb {
  font-weight: bold !important;
}

.u-fwm {
  font-weight: 500 !important;
}

.u-align--r {
  text-align: right !important;
}

.u-align--c {
  text-align: center !important;
}
@media screen and (min-width: 768px) {
  .u-align--c--pc {
    text-align: center !important;
  }
}

.u-align-l {
  text-align: left !important;
}
@media screen and (max-width: 767px) {
  .u-align-l--sp {
    text-align: left !important;
  }
}

.u-ff02 {
  font-family: Georgia, Times, "Times New Roman", serif;
}

.u-lh200 {
  line-height: 2 !important;
}

.u-uline {
  text-decoration: underline !important;
}
.u-uline:hover, .u-uline:focus {
  text-decoration: none !important;
}

.u-text--10 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--10 {
    font-size: calc(10 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--10 {
    font-size: calc(10 * 0.06rem) !important;
  }
}

.u-text--11 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--11 {
    font-size: calc(11 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--11 {
    font-size: calc(11 * 0.06rem) !important;
  }
}

.u-text--12 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--12 {
    font-size: calc(12 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--12 {
    font-size: calc(12 * 0.06rem) !important;
  }
}

.u-text--13 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--13 {
    font-size: calc(13 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--13 {
    font-size: calc(13 * 0.06rem) !important;
  }
}

.u-text--14 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--14 {
    font-size: calc(14 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--14 {
    font-size: calc(14 * 0.06rem) !important;
  }
}

.u-text--15 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--15 {
    font-size: calc(15 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--15 {
    font-size: calc(15 * 0.06rem) !important;
  }
}

.u-text--16 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--16 {
    font-size: calc(16 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--16 {
    font-size: calc(16 * 0.06rem) !important;
  }
}

.u-text--17 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--17 {
    font-size: calc(17 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--17 {
    font-size: calc(17 * 0.06rem) !important;
  }
}

.u-text--18 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--18 {
    font-size: calc(18 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--18 {
    font-size: calc(18 * 0.06rem) !important;
  }
}

.u-text--19 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--19 {
    font-size: calc(19 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--19 {
    font-size: calc(19 * 0.06rem) !important;
  }
}

.u-text--20 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--20 {
    font-size: calc(20 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--20 {
    font-size: calc(20 * 0.06rem) !important;
  }
}

.u-text--21 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--21 {
    font-size: calc(21 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--21 {
    font-size: calc(21 * 0.06rem) !important;
  }
}

.u-text--22 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--22 {
    font-size: calc(22 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--22 {
    font-size: calc(22 * 0.06rem) !important;
  }
}

.u-text--23 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--23 {
    font-size: calc(23 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--23 {
    font-size: calc(23 * 0.06rem) !important;
  }
}

.u-text--24 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--24 {
    font-size: calc(24 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--24 {
    font-size: calc(24 * 0.06rem) !important;
  }
}

.u-text--25 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--25 {
    font-size: calc(25 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--25 {
    font-size: calc(25 * 0.06rem) !important;
  }
}

.u-text--26 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--26 {
    font-size: calc(26 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--26 {
    font-size: calc(26 * 0.06rem) !important;
  }
}

.u-text--27 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--27 {
    font-size: calc(27 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--27 {
    font-size: calc(27 * 0.06rem) !important;
  }
}

.u-text--28 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--28 {
    font-size: calc(28 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--28 {
    font-size: calc(28 * 0.06rem) !important;
  }
}

.u-text--29 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--29 {
    font-size: calc(29 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--29 {
    font-size: calc(29 * 0.06rem) !important;
  }
}

.u-text--30 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--30 {
    font-size: calc(30 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--30 {
    font-size: calc(30 * 0.06rem) !important;
  }
}

.u-text--31 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--31 {
    font-size: calc(31 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--31 {
    font-size: calc(31 * 0.06rem) !important;
  }
}

.u-text--32 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--32 {
    font-size: calc(32 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--32 {
    font-size: calc(32 * 0.06rem) !important;
  }
}

.u-text--33 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--33 {
    font-size: calc(33 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--33 {
    font-size: calc(33 * 0.06rem) !important;
  }
}

.u-text--34 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--34 {
    font-size: calc(34 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--34 {
    font-size: calc(34 * 0.06rem) !important;
  }
}

.u-text--35 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--35 {
    font-size: calc(35 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--35 {
    font-size: calc(35 * 0.06rem) !important;
  }
}

.u-text--36 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--36 {
    font-size: calc(36 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--36 {
    font-size: calc(36 * 0.06rem) !important;
  }
}

.u-text--37 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--37 {
    font-size: calc(37 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--37 {
    font-size: calc(37 * 0.06rem) !important;
  }
}

.u-text--38 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--38 {
    font-size: calc(38 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--38 {
    font-size: calc(38 * 0.06rem) !important;
  }
}

.u-text--39 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--39 {
    font-size: calc(39 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--39 {
    font-size: calc(39 * 0.06rem) !important;
  }
}

.u-text--40 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--40 {
    font-size: calc(40 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--40 {
    font-size: calc(40 * 0.06rem) !important;
  }
}

.u-text--41 {
  font-feature-settings: "palt";
  letter-spacing: 0.05em;
}
@media screen and (min-width: 1024px) {
  .u-text--41 {
    font-size: calc(41 * 0.0625rem) !important;
  }
}
@media screen and (max-width: 1023px) {
  .u-text--41 {
    font-size: calc(41 * 0.06rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--10--sp {
    font-size: calc(10 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--11--sp {
    font-size: calc(11 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--12--sp {
    font-size: calc(12 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--13--sp {
    font-size: calc(13 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--14--sp {
    font-size: calc(14 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--15--sp {
    font-size: calc(15 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--16--sp {
    font-size: calc(16 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--17--sp {
    font-size: calc(17 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--18--sp {
    font-size: calc(18 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--19--sp {
    font-size: calc(19 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--20--sp {
    font-size: calc(20 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--21--sp {
    font-size: calc(21 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--22--sp {
    font-size: calc(22 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--23--sp {
    font-size: calc(23 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--24--sp {
    font-size: calc(24 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--25--sp {
    font-size: calc(25 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--26--sp {
    font-size: calc(26 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--27--sp {
    font-size: calc(27 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--28--sp {
    font-size: calc(28 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--29--sp {
    font-size: calc(29 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--30--sp {
    font-size: calc(30 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--31--sp {
    font-size: calc(31 * 0.0714285714rem) !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-text--32--sp {
    font-size: calc(32 * 0.0714285714rem) !important;
  }
}